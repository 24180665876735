import { AxiosInstance, AxiosResponse } from 'axios';

import { Location } from '../models/gps-model';
import { store } from '../store/store';

interface LocationService {
  getLastLocation: (sessionId: string) => Promise<Location | null>;
}

const locationService = (httpClient: AxiosInstance): LocationService => {
  return {
    getLastLocation: async (sessionId: string) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return null;
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id, sessionId };
        const res: AxiosResponse<Location> = await httpClient.get('/locations/last-location', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
  };
};

export default locationService;
