import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import {
  DelayedPointsCollection,
  DeviceUploadResponse,
  LocationList,
  PanicList,
  ParsedImportDeviceData,
  RawDataList,
} from '../models/device-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';
import { DeviceCollection } from './../models/device-model';

interface DeviceService {
  getDeviceList: () => Promise<string[]>;
  getPanicList: (deviceId: string) => Promise<PanicList[]>;
  deletePanic: (deviceId: string) => Promise<APIResponse>;
  getLocationList: (deviceId: string) => Promise<LocationList[]>;
  deleteLocations: (deviceId: string) => Promise<APIResponse>;
  getDelayedPoints: (deviceId: string) => Promise<DelayedPointsCollection[]>;
  deleteDelayedPoints: (deviceId: string) => Promise<APIResponse>;
  getRawDataList: (deviceId: string) => Promise<RawDataList[]>;
  deleteRawData: (deviceId: string) => Promise<APIResponse>;
  getDevices: () => Promise<DeviceCollection[]>;
  addDevice: (driver: DeviceCollection) => Promise<APIResponse>;
  updateDevice: (driver: DeviceCollection, initialValues: DeviceCollection) => Promise<APIResponse>;
  deleteDevice: (id: string) => Promise<APIResponse>;
  importDevices: (fileData: FormData) => Promise<DeviceUploadResponse>;
  addBulkDeviceOnImport: (data: ParsedImportDeviceData[], key: number | string) => Promise<APIResponse>;
}

const deviceService = (httpClient: AxiosInstance): DeviceService => {
  return {
    getDeviceList: async () => {
      try {
        const res: AxiosResponse<string[]> = await httpClient.get('/devices/location');
        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    getPanicList: async (deviceId: string) => {
      try {
        const params = { deviceId: deviceId };
        const res: AxiosResponse<PanicList[]> = await httpClient.get('/devices/panic', { params });
        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    deletePanic: async (deviceId: string) => {
      const errorResponse: APIResponse = {
        status: false,
      };
      try {
        const params = { deviceId: deviceId };
        const res: AxiosResponse<APIResponse> = await httpClient.delete('/devices/panic', { params });
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    getLocationList: async (deviceId: string) => {
      try {
        const params = { deviceId: deviceId };
        const res: AxiosResponse<LocationList[]> = await httpClient.get('/locations', { params });
        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    deleteLocations: async (deviceId: string) => {
      const errorResponse: APIResponse = {
        status: false,
      };
      try {
        const params = { deviceId: deviceId };
        const res: AxiosResponse<APIResponse> = await httpClient.delete('/devices/location', { params });
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    getDelayedPoints: async (deviceId: string) => {
      try {
        const params = { deviceId: deviceId };
        const res: AxiosResponse<DelayedPointsCollection[]> = await httpClient.get('/rfids/delayed-points', { params });
        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    deleteDelayedPoints: async (deviceId: string) => {
      const errorResponse: APIResponse = {
        status: false,
      };
      try {
        const params = { deviceId: deviceId };
        const res: AxiosResponse<APIResponse> = await httpClient.delete('/rfids/delayed-points', { params });
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    getRawDataList: async (deviceId: string) => {
      try {
        const params = { deviceId: deviceId };
        const res: AxiosResponse<RawDataList[]> = await httpClient.get('/devices/raw-data', { params });
        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    deleteRawData: async (deviceId: string) => {
      const errorResponse: APIResponse = {
        status: false,
      };
      try {
        const params = { deviceId: deviceId };
        const res: AxiosResponse<APIResponse> = await httpClient.delete('/devices/raw-data', { params });
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    getDevices: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<DeviceCollection[]> = await httpClient.get('/devices', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    deleteDevice: async (id: string) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.delete(`/devices/${id}`);
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to delete device.' };
      }
    },

    addDevice: async (data) => {
      try {
        const diff = jsonDifference(data);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/devices', { ...data, diff });
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to add device.' };
      }
    },

    updateDevice: async (data, initialData) => {
      try {
        const diff = jsonDifference(data, initialData);
        const res: AxiosResponse<APIResponse> = await httpClient.put(`/devices/${data._id}`, { ...data, diff });
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to update device.' };
      }
    },

    importDevices: async (data: FormData) => {
      const errorResponse: DeviceUploadResponse = { error: 'Upload failed', filePath: '', resultData: null };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<DeviceUploadResponse> = await httpClient.post('/devices/import', data);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    // TODO: It's import array
    addBulkDeviceOnImport: async (data, key) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/devices/create-list', { data, key });
        return res.data;
      } catch (err) {
        return { status: false, result: data };
      }
    },
  };
};

export default deviceService;
