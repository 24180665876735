import { LoginData, LoginResponse } from '../models/auth-model';

export const formatAuth = (responseData: LoginResponse): LoginData | null => {
  const { userDetails, tokenDetails } = responseData;
  if (!userDetails || !tokenDetails) {
    return null;
  }

  return responseData;
};
