import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';
import {
  AdminUserInvite,
  RoleWithPermissionObj,
  UserResponse,
  UserRoleAddAndUpdate,
} from './../models/user-management-model';

interface UserManagementService {
  getAdminUser: () => Promise<UserResponse[]>;
  createUser: (data: AdminUserInvite) => Promise<APIResponse>;
  updateUser: (data: AdminUserInvite, initialData: UserResponse) => Promise<APIResponse>;
  updateUserStatus: (userId: string, isDisabled: boolean) => Promise<APIResponse>;
  getRoles: () => Promise<RoleWithPermissionObj[]>;
  createRole: (data: UserRoleAddAndUpdate) => Promise<APIResponse>;
  updateRole: (data: UserRoleAddAndUpdate, initialData: RoleWithPermissionObj) => Promise<APIResponse>;
}

const errorResponse: APIResponse = {
  status: false,
};

const userManagementService = (httpClient: AxiosInstance): UserManagementService => {
  return {
    getAdminUser: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<UserResponse[]> = await httpClient.get('/user-management/user', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    createUser: async (data) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        data.trust = selectedTrust._id;
        const diff = jsonDifference(data);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/user-management/user', { ...data, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updateUser: async (data, initialData) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        data.trust = selectedTrust._id;
        const diff = jsonDifference(data, initialData);
        const res: AxiosResponse<APIResponse> = await httpClient.put('/user-management/user', { ...data, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updateUserStatus: async (userId: string, isDisabled: boolean) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }
        const diff = jsonDifference({ isDisabled });

        const res: AxiosResponse<APIResponse> = await httpClient.put('/user-management/user/status', {
          userId,
          isDisabled,
          trust: selectedTrust._id,
          diff,
        });

        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to update' };
      }
    },
    getRoles: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<RoleWithPermissionObj[]> = await httpClient.get('/user-management/role', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    createRole: async (data) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        data.trust = selectedTrust._id;
        const diff = jsonDifference(data);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/user-management/role', { ...data, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updateRole: async (data, initialData) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        data.trust = selectedTrust._id;
        const diff = jsonDifference(data, initialData);
        const res: AxiosResponse<APIResponse> = await httpClient.put('/user-management/role', { ...data, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },
  };
};

export default userManagementService;
