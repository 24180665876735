import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import {
  ParsedImportSections,
  SchoolByCities,
  SchoolCollection,
  SchoolDetails,
  SectionDetailDeleteRequest,
  SectionUI,
  SectionUploadResponse,
} from '../models/school-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';
import { DivisionUI, StandardUI } from './../models/school-model';

interface SchoolService {
  getSchool: () => Promise<SchoolCollection[]>;
  getSchoolForStudents: () => Promise<SchoolByCities>;
  createSchool: (school: SchoolDetails) => Promise<APIResponse>;
  updateSchool: (school: SchoolDetails, initialData: SchoolCollection) => Promise<APIResponse>;
  updateSchoolSection: (section: SectionUI) => Promise<APIResponse>;
  updateSchoolStandard: (standard: StandardUI) => Promise<APIResponse>;
  updateSchoolDivision: (division: DivisionUI) => Promise<APIResponse>;
  deleteSchoolDetails: (division: SectionDetailDeleteRequest) => Promise<APIResponse>;
  deleteSchool: (school: SchoolDetails) => Promise<APIResponse>;
  importSectionList: (data: FormData) => Promise<SectionUploadResponse>;
  addSectionList: (data: ParsedImportSections, key: number | string) => Promise<APIResponse>;
}

const errorResponse: APIResponse = {
  status: false,
};

const schoolService = (httpClient: AxiosInstance): SchoolService => {
  return {
    getSchool: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<SchoolCollection[]> = await httpClient.get('/schools/schoollist', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    getSchoolForStudents: async () => {
      const response: SchoolByCities = {
        cities: [],
      };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return response;
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<SchoolByCities> = await httpClient.get('/schools', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return response;
      }
    },

    createSchool: async (school) => {
      try {
        const diff = jsonDifference(school);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/schools/addschool', { ...school, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updateSchool: async (schoolDetails, initialData) => {
      try {
        const diff = jsonDifference(schoolDetails, initialData);
        const res: AxiosResponse<APIResponse> = await httpClient.put('/schools/updateschool', {
          ...schoolDetails,
          diff,
        });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updateSchoolSection: async (section) => {
      try {
        const diff = jsonDifference(section);
        const payload = { ...section, ...getTrustByRole(), diff };
        const res: AxiosResponse<APIResponse> = await httpClient.put('/schools/section', payload);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updateSchoolStandard: async (standard) => {
      try {
        const diff = jsonDifference(standard);
        const payload = { ...standard, ...getTrustByRole(), diff };

        const res: AxiosResponse<APIResponse> = await httpClient.put('/schools/standard', payload);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updateSchoolDivision: async (division) => {
      try {
        const diff = jsonDifference(division);
        const payload = { ...division, ...getTrustByRole(), diff };
        const res: AxiosResponse<APIResponse> = await httpClient.put('/schools/division', payload);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    deleteSchoolDetails: async (data) => {
      try {
        const payload = { ...data, ...getTrustByRole() };
        const res: AxiosResponse<APIResponse> = await httpClient.put('/schools/delete-details', payload);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    deleteSchool: async (school) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.delete('schools/' + school._id);

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    importSectionList: async (data) => {
      const errorResponse: SectionUploadResponse = { error: 'Upload failed', filePath: '', resultData: null };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<SectionUploadResponse> = await httpClient.post('/schools/import', data);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    addSectionList: async (sectionData, key) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/schools/import/sections', { sectionData, key });
        return res.data;
      } catch (err) {
        return { status: false, result: sectionData };
      }
    },
  };
};

const getTrustByRole = () => {
  const state = store.getState();
  const selectedTrust = state.trust.selectedTrust;
  const authData = state.auth;
  if (!selectedTrust || !authData || !authData.userDetails) {
    return [];
  }

  return { role: authData.userDetails.role, trustId: selectedTrust._id };
};

export default schoolService;
