import axios, { AxiosRequestConfig } from 'axios';

import { apiConfig } from '../config/api-config';
import { disconnected, logout } from '../store/actions/actions';
import { store } from '../store/store';
import account from './account-service';
import auth from './auth-service';
import common from './common-service';
import connection from './connection-service';
import covid from './covid-service';
import developerApi from './developer-api-service';
import device from './device-service';
import email from './email-service';
import info from './info-service';
import location from './location-service';
import log from './log-service';
import panic from './panic-service';
import parent from './parent-service';
import permission from './permission-service';
import profile from './profile-service';
import registerStats from './register-stats-service';
import report from './report-service';
import rfid from './rfid-service';
import routeBuilder from './route-builder-service';
import route from './route-service';
import school from './school-service';
import session from './session-service';
import staff from './staff-service';
import stop from './stop-service';
import student from './student-service';
import trust from './trust-service';
import userManagement from './user-management-service';

const httpClient = axios.create(apiConfig);

httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const tokenDetails = store.getState().auth.tokenDetails;
  if (tokenDetails) {
    config.headers.Authorization = `Bearer ${tokenDetails.authToken}`;
  }

  return config;
});

httpClient.interceptors.response.use(undefined, (err) => {
  if (err.message === 'Network Error') {
    store.dispatch(disconnected());
    return;
  }

  if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
    logout();
    localStorage.clear();
    window.location.replace('/login');
    return;
  }

  if (err.response.status === 403 && err.config && !err.config.__isRetryRequest) {
    logout();
    localStorage.clear();
    window.location.replace('/login');
    return;
  }

  throw err;
});

const authService = auth(httpClient);
const staffService = staff(httpClient);
const trustService = trust(httpClient);
const stopService = stop(httpClient);
const schoolService = school(httpClient);
const routeService = route(httpClient);
const registerStatsService = registerStats(httpClient);
const reportService = report(httpClient);
const covidService = covid(httpClient);
const sessionService = session(httpClient);
const studentService = student(httpClient);
const rfidService = rfid(httpClient);
const deviceService = device(httpClient);
const accountService = account(httpClient);
const connectionService = connection(httpClient);
const emailService = email(httpClient);
const profileService = profile(httpClient);
const routeBuilderService = routeBuilder(httpClient);
const panicService = panic(httpClient);
const locationService = location(httpClient);
const parentService = parent(httpClient);
const developerApiService = developerApi(httpClient);
const logService = log(httpClient);
const infoService = info(httpClient);
const permissionService = permission(httpClient);
const userManagementService = userManagement(httpClient);
const commonService = common(httpClient);

export {
  authService,
  connectionService,
  covidService,
  deviceService,
  sessionService,
  staffService,
  registerStatsService,
  rfidService,
  routeService,
  reportService,
  schoolService,
  stopService,
  studentService,
  trustService,
  accountService,
  emailService,
  profileService,
  routeBuilderService,
  panicService,
  locationService,
  parentService,
  developerApiService,
  logService,
  infoService,
  permissionService,
  userManagementService,
  commonService,
};
