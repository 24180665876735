import { AxiosInstance, AxiosResponse } from 'axios';

import { Obj } from '../models/core-model';

interface PermissionService {
  getPermissions: () => Promise<Obj>;
}

const permissionService = (httpClient: AxiosInstance): PermissionService => {
  return {
    getPermissions: async () => {
      try {
        const res: AxiosResponse<Obj> = await httpClient.get('/user-management/permissions');
        return res ? res.data : {};
      } catch (err) {
        console.log(err);
        return {};
      }
    },
  };
};

export default permissionService;
