import { AxiosInstance, AxiosResponse } from 'axios';

import { AWSFileData } from '../models/aws-model';
import { APIResponse } from '../models/common-model';
import { ReportSessionDetails, Session, SessionLogResponse } from '../models/session-model';
import { store } from '../store/store';
import { RouteLogByDateRequest, RouteLogRequest } from './../models/route-model';

interface LogService {
  getRouteLog: (routeDetails: RouteLogRequest) => Promise<Session | null>;
  getDefectiveRouteLogs: (routeDetails: RouteLogByDateRequest) => Promise<{ data: ReportSessionDetails[] } | null>;
  addImportFileLogs: (data: AWSFileData) => Promise<APIResponse>;
  getSessionLogs: (sessionId: string) => Promise<APIResponse<SessionLogResponse>>;
}

const logService = (httpClient: AxiosInstance): LogService => {
  return {
    getRouteLog: async (data) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return null;
        }

        const params: RouteLogRequest = { ...data, role: authData.userDetails.role, trustId: selectedTrust._id };
        const res: AxiosResponse<Session> = await httpClient.get('/logs', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    getDefectiveRouteLogs: async (data) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return null;
        }

        const body: RouteLogByDateRequest = { ...data, role: authData.userDetails.role, trustId: selectedTrust._id };
        const res: AxiosResponse<{ data: ReportSessionDetails[] }> = await httpClient.post(
          '/report/defective-sessions',
          body,
        );

        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    addImportFileLogs: async (data) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/logs/rds', data);
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to add logs' };
      }
    },

    getSessionLogs: async (sessionId) => {
      try {
        const res: AxiosResponse<APIResponse<SessionLogResponse>> = await httpClient.get('/logs/session/' + sessionId);
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to add logs' };
      }
    },
  };
};

export default logService;
