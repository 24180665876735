import { Dispatch } from 'redux';

import { AuthActionType, LoginData } from '../../models/auth-model';
import { authService } from '../../services';

const logout = () => {
  return (dispatch: Dispatch): void => {
    authService.logout().finally(() => {
      dispatch({ type: AuthActionType.AUTH_LOGOUT });
    });
  };
};

const login = (username: string, password: string) => {
  return (dispatch: Dispatch): void => {
    authService
      .login(username, password)
      .then((data: LoginData) => {
        if (data && data.error) {
          dispatch({ payload: { error: data.error }, type: AuthActionType.AUTH_FAILED });
          return;
        }

        dispatch({ payload: { ...data, error: 'Success' }, type: AuthActionType.AUTH_SUCCESS });
      })
      .catch((err: unknown) => {
        console.error('failed', err);
        dispatch({ payload: { error: err }, type: AuthActionType.AUTH_FAILED });
      });
  };
};

const clearError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({ payload: { error: '' }, type: AuthActionType.AUTH_LOADING });
  };
};

export { login, logout, clearError };
