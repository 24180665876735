import { Dispatch } from 'redux';

import { permissionService } from '../../services';
import { APIResponse } from './../../models/common-model';
import { PermissionActionTypes } from './../../models/permission-model';

const permissions = () => {
  return (dispatch: Dispatch): void => {
    permissionService
      .getPermissions()
      .then((permissions: APIResponse) => {
        dispatch({ payload: { permissions }, type: PermissionActionTypes.PERMISSION });
      })
      .catch((err: unknown) => {
        console.log(err);
        dispatch({ payload: { permissions: {} }, type: PermissionActionTypes.PERMISSION });
      });
  };
};

export { permissions };
