import { LoginData } from '../models/auth-model';
import { Trust } from '../models/trust-model';

export const getAuthStorage = (): LoginData | null => {
  const data = localStorage.getItem('data');
  return data ? JSON.parse(data) : null;
};

export const setAuthStorage = (data: LoginData): void => {
  localStorage.setItem('data', JSON.stringify(data));
};

export const getTrustStorage = (): Trust | null => {
  const data = localStorage.getItem('trust');
  return data ? JSON.parse(data) : null;
};

export const setTrustStorage = (trust?: Trust): void => {
  localStorage.setItem('trust', JSON.stringify(trust || ''));
};

export const clearStorageData = (): void => {
  localStorage.clear();
};
