import { AxiosRequestConfig } from 'axios';

import { ENV } from '../models/core-model';

const processENV: ENV = process.env;
const serverURL = processENV.REACT_APP_API_URL || 'http://localhost:4200';

export const apiConfig: AxiosRequestConfig = {
  baseURL: serverURL,
  timeout: 30000,
};
