import { AxiosInstance, AxiosResponse } from 'axios';

import { LoginData, LoginResponse } from '../models/auth-model';
import { formatAuth } from '../utils/auth-utils';
import { clearStorageData, setAuthStorage } from '../utils/storage-utils';

interface AuthService {
  login: (login: string, password: string) => Promise<LoginData>;
  logout: () => Promise<void>;
}

const authService = (httpClient: AxiosInstance): AuthService => {
  return {
    login: async (login: string, password: string): Promise<LoginData> => {
      try {
        const res: AxiosResponse<LoginResponse> = await httpClient.post('/auth/login', { login, password });
        const result = formatAuth(res.data);
        if (!result) {
          return { error: res.data.error };
        }

        setAuthStorage(result);
        return result;
      } catch (err) {
        return { error: 'Failed to login' };
      }
    },

    logout: async () => {
      try {
        await httpClient.post('/auth/logout', {});
        clearStorageData();
      } catch (err) {
        clearStorageData();
        return;
      }
    },
  };
};

export default authService;
