import { AxiosInstance, AxiosResponse } from 'axios';

import { RegisteredStatsResponse } from '../models/registered-stats-model';
import { store } from '../store/store';

interface RegisterStatsService {
  getRegisteredStats: () => Promise<RegisteredStatsResponse[]>;
}

const registerStatsService = (httpClient: AxiosInstance): RegisterStatsService => {
  return {
    getRegisteredStats: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        // const authData = state.auth;
        if (!selectedTrust) {
          return [];
        }

        const params = { trustId: selectedTrust._id };
        const res: AxiosResponse<RegisteredStatsResponse[]> = await httpClient.get('/registered-stats', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },
  };
};

export default registerStatsService;
