import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { PanicCollection } from '../models/panic-model';
import { store } from '../store/store';

interface PanicService {
  getPanics: () => Promise<PanicCollection[]>;
  resolvePanic: (panicId: string, deviceId: string) => Promise<APIResponse>;
}

const panicService = (httpClient: AxiosInstance): PanicService => {
  return {
    getPanics: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<PanicCollection[]> = await httpClient.get('/panic', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    resolvePanic: async (panicId: string, deviceId: string) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.put('/panic/resolve/' + panicId, {
          deviceId,
        });

        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Token invalid' };
      }
    },
  };
};

export default panicService;
