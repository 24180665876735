import { combineReducers } from 'redux';

import { authReducer } from './auth-reducer';
import { connectionReducer } from './connection-reducer';
import { permissionReducer } from './permission-reducer';
import settingsReducer from './settings-reducer';
import themesReducer from './themes-reducers';
import { trustReducer } from './trust-reducer';

export default combineReducers({
  auth: authReducer,
  theme: themesReducer,
  settings: settingsReducer,
  trust: trustReducer,
  connection: connectionReducer,
  permission: permissionReducer,
});
