import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import {
  DeleteStudentModel,
  PromoteResponse,
  PromoteStudentResponse,
  PromoteUploadResponse,
  StudentCollection,
  StudentRouteResponse,
  StudentStatus,
  UpdateBulkStudentRoutesResponse,
  UploadBulkStudentRoutesResponse,
  UploadData,
  UploadResponse,
} from '../models/student-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';

interface StudentService {
  getStudents: (schoolId: string) => Promise<StudentCollection[]>;
  getSessionStudents: (sessionId: string) => Promise<StudentStatus[]>;
  getSingleStudent: (id: string) => Promise<StudentCollection | null>;
  getStudentByUid: (uid: string) => Promise<StudentCollection | null>;
  deleteStudents: (rowSelected: string[]) => Promise<APIResponse>;
  stopBusService: (rowSelected: string[]) => Promise<APIResponse>;
  deleteStudent: (row: DeleteStudentModel) => Promise<APIResponse>;
  updateStudent: (
    studentId: string,
    studentDetails: StudentCollection,
    initialValues?: StudentCollection,
  ) => Promise<APIResponse>;
  createStudent: (studentDetails: StudentCollection) => Promise<APIResponse>;
  uploadStudent: (fileData: FormData) => Promise<UploadResponse>;
  importStudent: (request: UploadData[]) => Promise<PromoteStudentResponse>;
  uploadPromoteStudent: (fileData: FormData) => Promise<PromoteUploadResponse>;
  updatePromoteStudent: (request: PromoteResponse[]) => Promise<PromoteStudentResponse>;
  uploadStudentsRoute: (fileData: FormData) => Promise<UploadBulkStudentRoutesResponse>;
  updateStudentsRoute: (
    request: StudentRouteResponse[],
    key: number | string,
  ) => Promise<UpdateBulkStudentRoutesResponse>;
  studentsCount: (schoolId: string) => Promise<APIResponse<{ studentsCount: number }>>;
}

const studentService = (httpClient: AxiosInstance): StudentService => {
  return {
    getStudents: async (schoolId) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return [];
        }

        const params = {
          role: 'admin',
          trustId: selectedTrust._id,
          schoolId: schoolId.toLowerCase() === 'all' ? null : schoolId,
        };
        const res: AxiosResponse<StudentCollection[]> = await httpClient.get('/students/all', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    deleteStudents: async (rowSelected: string[]) => {
      const errorResponse: APIResponse = {
        status: false,
      };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<APIResponse> = await httpClient.post('/students/multipledelete', rowSelected);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    deleteStudent: async (rowSelected: DeleteStudentModel) => {
      const errorResponse: APIResponse = {
        status: false,
      };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<APIResponse> = await httpClient.post('/students/removestudentinfo', rowSelected);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    stopBusService: async (rowSelected: string[]) => {
      const errorResponse: APIResponse = {
        status: false,
      };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<APIResponse> = await httpClient.post('/stops/withdrawbusservice', rowSelected);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    createStudent: async (data: StudentCollection) => {
      const errorResponse: APIResponse = { status: false };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const diff = jsonDifference(data);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/students', {
          ...data,
          trust: selectedTrust._id,
          diff,
        });
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updateStudent: async (studentId, data, initialValues) => {
      const errorResponse: APIResponse = {
        status: false,
      };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const diff = jsonDifference(data, initialValues);
        const res: AxiosResponse<APIResponse> = await httpClient.put('/students/' + studentId, {
          ...data,
          trust: selectedTrust._id,
          diff,
        });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    getSingleStudent: async (id: string) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust || id === '') {
          return null;
        }

        const res: AxiosResponse<StudentCollection> = await httpClient.get('/students/' + id);
        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    getSessionStudents: async (sessionId) => {
      try {
        const params = { sessionId: sessionId };
        const res: AxiosResponse<StudentStatus[]> = await httpClient.get('/sessions/students', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    getStudentByUid: async (uid) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return null;
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id, uid };
        const res: AxiosResponse<StudentCollection[]> = await httpClient.get('/students/get-by-uid', { params });

        return res.data[0];
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    uploadStudent: async (data: FormData) => {
      const errorResponse: UploadResponse = {
        error: 'Upload failed',
        filePath: '',
        resultData: null,
      };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<UploadResponse> = await httpClient.post('/students/import', data);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    uploadPromoteStudent: async (data: FormData) => {
      const errorResponse: PromoteUploadResponse = {
        error: 'Upload failed',
        filePath: '',
        resultData: null,
      };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<PromoteUploadResponse> = await httpClient.post('/students/import', data);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    uploadStudentsRoute: async (data: FormData) => {
      const errorResponse: UploadBulkStudentRoutesResponse = { error: 'Upload failed', filePath: '', resultData: null };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<UploadBulkStudentRoutesResponse> = await httpClient.post('/students/import', data);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updatePromoteStudent: async (data: PromoteResponse[]) => {
      const errorResponse: PromoteStudentResponse = { status: 0, result: [] };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<PromoteStudentResponse> = await httpClient.post('/students/promotestudents', data);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updateStudentsRoute: async (data: StudentRouteResponse[], key: number | string) => {
      const errorResponse: UpdateBulkStudentRoutesResponse = { status: 0, result: [], key };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<UpdateBulkStudentRoutesResponse> = await httpClient.post(
          '/students/import/update-routes',
          { data, key },
        );
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    importStudent: async (data: UploadData[]) => {
      const errorResponse: PromoteStudentResponse = {
        status: 0,
        result: [],
      };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<PromoteStudentResponse> = await httpClient.post('/students/import-students', data);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    studentsCount: async (schoolId: string) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return { status: false, message: 'Invalid' };
        }

        const params = { role: 'admin', trustId: selectedTrust._id, schoolId };
        const res: AxiosResponse<APIResponse<{ studentsCount: number }>> = await httpClient.get('/students/count', {
          params,
        });

        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Invalid' };
      }
    },
  };
};

export default studentService;
