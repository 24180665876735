import { getAuthStorage } from '../../utils/storage-utils';
import { AuthAction, AuthActionType, AuthState } from './../../models/auth-model';

const storage = getAuthStorage();
const initialState: AuthState = {
  loading: false,
  tokenDetails: storage ? storage.tokenDetails : undefined,
  userDetails: storage ? storage.userDetails : undefined,
  error: undefined,
};

const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionType.AUTH_LOADING:
      return { ...state, loading: true, error: undefined };

    case AuthActionType.AUTH_SUCCESS:
      if (!action.payload || !action.payload.userDetails || !action.payload.tokenDetails) {
        return state;
      }

      return { ...state, ...action.payload, loading: false, error: undefined };

    case AuthActionType.AUTH_LOGOUT:
      return clearAuthState(state);

    case AuthActionType.AUTH_FAILED:
      return clearAuthState(state, action.payload.error);

    default:
      return state;
  }
};

const clearAuthState = (state: AuthState, err?: string) => {
  const newState = { ...state, loading: false, error: err };

  delete newState.tokenDetails;
  delete newState.userDetails;
  return newState;
};

export { authReducer };
