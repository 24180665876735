import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { StaffCollection } from '../models/staff-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';

interface StaffService {
  getStaffs: () => Promise<StaffCollection[]>;
  addStaff: (staff: StaffCollection) => Promise<APIResponse>;
  updateStaff: (staff: StaffCollection, initialData: StaffCollection) => Promise<APIResponse>;
  deleteStaff: (id: string) => Promise<APIResponse>;
}

const staffService = (httpClient: AxiosInstance): StaffService => {
  return {
    getStaffs: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<StaffCollection[]> = await httpClient.get('/staffs', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    deleteStaff: async (id: string) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.delete(`/staffs/${id}`);
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to delete staff.' };
      }
    },

    addStaff: async (data) => {
      try {
        const diff = jsonDifference(data);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/staffs', { ...data, diff });
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to add staff.' };
      }
    },

    updateStaff: async (data, initialData) => {
      try {
        const diff = jsonDifference(data, initialData);
        const res: AxiosResponse<APIResponse> = await httpClient.put(`/staffs/${data._id}`, { ...data, diff });
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to update staff.' };
      }
    },
  };
};

export default staffService;
