/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Switch, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { nonMenuRoutes, routes } from '.';
const Dashboard = lazy(() => import('../pages/Dashboard'));

const Device = lazy(() => import('../pages/Device'));
const Devices = lazy(() => import('../pages/Devices'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));

import PrivateRoute from '../components/common/PrivateRoute';
import PageLoader from '../components/themes/Common/PageLoader';
import Base from '../components/themes/Layout/Base';
import BasePage from '../components/themes/Layout/BasePage';
import { Permissions } from '../models/permission-model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props} />;

const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const VerifyEmail = lazy(() => import('../pages/VerifyEmail'));
const VerifyEmailAndCreatePassword = lazy(() => import('../pages/VerifyEmailAndCreatePassword'));

const Routes = ({ location }: RouteProps) => {
  const permissions = useSelector((state: { permission: Permissions }) => state.permission.permissions);
  const [filteredRoutes, setFilteredRoutes] = useState<JSX.Element[]>([]);

  const privateRoutes = (): void => {
    const rootRoutes: JSX.Element[] = [];
    const menuList = [...routes, ...nonMenuRoutes];
    let hasPermission = false;

    menuList.forEach((route, i) => {
      if (!Array.isArray(route.properties)) {
        const key = route.properties.showPermission as unknown as keyof typeof permissions;

        if (!permissions[key] || !route.component) {
          return;
        }

        rootRoutes.push(
          <PrivateRoute key={`route-${i}`} path={route.path} exact component={waitFor(route.component)} />,
        );
        hasPermission = true;
        return;
      }

      route.properties.forEach((subRoute, j) => {
        const key = subRoute.showPermission as unknown as keyof typeof permissions;
        if (!permissions[key] || !subRoute.component) {
          return;
        }

        rootRoutes.push(
          <PrivateRoute
            key={`route-${i}-${j}`}
            path={`${route.path}${subRoute.path}`}
            exact
            component={waitFor(subRoute.component)}
          />,
        );
        hasPermission = true;
      });
    });

    if (!hasPermission) {
      rootRoutes.push(<PrivateRoute key="default" path={`/`} exact component={waitFor(Dashboard)} />);
    }

    setFilteredRoutes(rootRoutes);
  };

  useEffect(() => {
    privateRoutes();
  }, [permissions]);

  const currentKey = location!.pathname.split('/')[1] || '/';
  const timeout = { enter: 500, exit: 500 };

  const animationName = 'rag-fadeIn';

  const pages = ['/login', '/device', '/forgot-password', '/devices', '/verify-email', '/verify-user', '/reset'];
  if (pages.indexOf(currentKey ? `/${currentKey}` : '') > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/login" component={waitFor(Login)} />
            <Route path="/forgot-password" component={waitFor(ForgotPassword)} />
            <Route path="/reset/:token" component={waitFor(ResetPassword)} />
            <Route path="/device" exact component={waitFor(Device)} />
            <Route path="/devices" component={waitFor(Devices)} />
            <Route path="/verify-email/:token" component={waitFor(VerifyEmail)} />
            <Route path="/verify-user/:token" component={waitFor(VerifyEmailAndCreatePassword)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  }

  return (
    <Base>
      <TransitionGroup>
        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
          <Suspense fallback={<PageLoader />}>
            <Switch location={location}>
              {filteredRoutes.length > 0 && (
                <>
                  {filteredRoutes}
                  {/* <PrivateRoute path="/manage-students" exact component={waitFor(ManageStudents)} /> */}
                  {/* <PrivateRoute path="/manage-routes" exact component={waitFor(ManageRoutes)} /> */}
                  {/* <PrivateRoute path="/manage-devices/import" exact component={waitFor(ImportDevices)} />
                  <PrivateRoute path="/manage-attendants" exact component={waitFor(ManageAttendants)} />
                  <PrivateRoute path="/manage-accounts" exact component={waitFor(ManageAccounts)} />
                  <PrivateRoute path="/add-user" exact component={waitFor(AddUser)} />
                  <PrivateRoute path="/add-stop" component={waitFor(AddStop)} />
                  <PrivateRoute path="/stop-collection" component={waitFor(StopCollection)} />
                  <PrivateRoute path="/manage-stops/import" exact component={waitFor(ImportStops)} />
                  <PrivateRoute path="/add-student" component={waitFor(AddStudent)} />
                  <PrivateRoute path="/import-students" component={waitFor(ImportStudents)} />
                  <PrivateRoute path="/manage-routes/import" exact component={waitFor(ImportRoutes)} />
                  <PrivateRoute path="/multi-route-creator" exact component={waitFor(MultiRouteCreator)} />
                  <PrivateRoute path="/manage-rfids/import-route-rfids" exact component={waitFor(ImportRouteRFIDs)} />
                  <PrivateRoute path="/manage-rfids/import-staff-rfids" exact component={waitFor(ImportStaffRFIDs)} />
                  <PrivateRoute path="/manage-rfids/import-device-rfids" exact component={waitFor(ImportDeviceRFIDs)} />
                  <PrivateRoute path="/add-rfid" exact component={waitFor(AddRFID)} />
                  <PrivateRoute path="/group-assign-rfid" exact component={waitFor(GroupAssignRFID)} />
                  <PrivateRoute path="/import-student-rfids" exact component={waitFor(ImportRFIDs)} />
                  <PrivateRoute path="/manage-sections" exact component={waitFor(ManageSections)} />;
                  <PrivateRoute path="/add-trust" exact component={waitFor(AddTrust)} />
                  <PrivateRoute path="/add-route" exact component={waitFor(AddRoute)} />
                  <PrivateRoute path="/manage-routes/edit-stops" exact component={waitFor(EditStops)} />
                  <PrivateRoute path="/covid" exact component={waitFor(Covid)} />
                  <PrivateRoute path="/manage-infos/info" exact component={waitFor(ManageParentInfo)} /> */}
                  {/* Default */}
                </>
              )}
              <Route path="/login" component={waitFor(Login)} />
              <Route path="/forgot-password" component={waitFor(ForgotPassword)} />
              {/* <Redirect to="/dashboard" /> */}
            </Switch>
          </Suspense>
        </CSSTransition>
      </TransitionGroup>
    </Base>
  );
};

export default withRouter(Routes);
