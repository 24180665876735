import { Action } from 'redux';

const disconnected = (): Action => {
  return {
    type: 'DISCONNECTED',
  };
};

const reconnected = (): Action => {
  return {
    type: 'CONNECTED',
  };
};

export { disconnected, reconnected };
