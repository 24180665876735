import { Dispatch } from 'redux';

import { Trust, TrustActionTypes } from '../../models/trust-model';
import { trustService } from '../../services';
import { setTrustStorage } from '../../utils/storage-utils';
import { getSelectedTrust } from '../../utils/trust-utils';
import { TrustAction } from './../../models/trust-model';
import { getTrustStorage } from './../../utils/storage-utils';

const trustList = () => {
  return (dispatch: Dispatch): void => {
    trustService
      .getTrusts()
      .then((data: Trust[]) => {
        let selectedTrust = getTrustStorage();
        if (!selectedTrust) {
          selectedTrust = data[0];
          setTrustStorage(selectedTrust);
        }

        dispatch({ payload: { trusts: data, selectedTrust }, type: TrustActionTypes.TRUST_SET });
      })
      .catch((err: unknown) => {
        console.log(err);
        dispatch({ payload: { trusts: [] }, type: TrustActionTypes.TRUST_SET });
      });
  };
};

const setSelectedTrust = (id: string, trusts: Trust[]): TrustAction => {
  const selectedTrust = getSelectedTrust(id, trusts);

  setTrustStorage(selectedTrust);

  return {
    payload: { selectedTrust: selectedTrust, trusts },
    type: TrustActionTypes.TRUST_SELECTED,
  };
};

export { trustList, setSelectedTrust };
