import { Action } from 'redux';

export interface Trust {
  _id: string;
  trustName: string;
  trustCode: string;
  city: string;
}

export enum TrustActionTypes {
  TRUST_SET = 'TRUST_SET',
  TRUST_SELECTED = 'TRUST_SELECTED',
  TRUST_FAILED = 'TRUST_FAILED',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
}

export interface TrustAction extends Action {
  type: TrustActionTypes;
  payload: TrustState;
}

export interface TrustState {
  trusts: Trust[];
  selectedTrust?: Trust;
}

export interface TrustDetails {
  _id?: string;
  trustName: string;
  trustCode: string;
  city: string;
}

export interface BatchTypeRequest {
  trustId: string;
  batchType: string;
}

export interface UpdateBatchTypeRequest extends BatchTypeRequest {
  oldBatchType: string;
}
