export enum SocketEvents {
  TRIP_RFID_SCANNED = 'tripRfidScanned',
  STUDENT_RFID_SCAN = 'studentRFIDScan',
  STOP_STATUS = 'stopStatus',
  AXIS_UPDATE = 'axisUpdate',
  PANIC_EVENT = 'panicEvent',
  PANIC_RESOLVED = 'panicResolved',
  ROUTE_BUILDER_SESSION_SCANNED = 'routeBuilderSessionScanned',
  ROUTE_BUILDER_STOP_SCANNED = 'newStop',
}
