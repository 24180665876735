import { AxiosInstance, AxiosResponse } from 'axios';

import { Account, AccountForm } from '../models/account-model';
import { APIResponse } from '../models/common-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';

interface AccountService {
  getAccounts: () => Promise<Account[]>;
  createAccounts: (formData: AccountForm) => Promise<APIResponse>;
  updatePassword: (userId: string, newPassword: string) => Promise<APIResponse>;
  deleteAccount: (userId: string) => Promise<APIResponse>;
}

const AccountService = (httpClient: AxiosInstance): AccountService => {
  return {
    getAccounts: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id, userId: authData.userDetails.userId };
        const res: AxiosResponse<Account[]> = await httpClient.get('/users', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    deleteAccount: async (userId) => {
      try {
        const payload = { userId: userId };
        const res: AxiosResponse<APIResponse> = await httpClient.post('/users/delete', payload);
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to delete user.' };
      }
    },

    updatePassword: async (userId, newPassword) => {
      try {
        const payload = { id: userId, newPassword: newPassword };
        const res: AxiosResponse<APIResponse> = await httpClient.post('/users/reset-password', payload);
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to update user Password.' };
      }
    },

    createAccounts: async (formData) => {
      try {
        const diff = jsonDifference(formData);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/signup', { ...formData, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to create user.' };
      }
    },
  };
};

export default AccountService;
