import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';

import { ApplicationState } from '../../../store/store';

export interface SidebarUserBlockProps {
  showUserBlock: boolean;
}

class SidebarUserBlock extends Component<SidebarUserBlockProps> {
  state = {
    showUserBlock: false,
  };

  UNSAFE_componentWillReceiveProps(newProps: SidebarUserBlockProps): void {
    if (newProps.showUserBlock !== this.props.showUserBlock) {
      this.setState({ showUserBlock: newProps.showUserBlock });
    }
  }

  render(): ReactNode {
    return (
      <Collapse id="user-block" isOpen={this.state.showUserBlock}>
        <div>
          <div className="item user-block">
            {/* User picture */}
            <div className="user-block-picture">
              <div className="user-block-status">
                <img className="img-thumbnail rounded-circle" src="img/logo.png" alt="Avatar" width="60" height="60" />
                <div className="circle bg-success circle-lg"></div>
              </div>
            </div>
            {/* Name and Job */}
            <div className="user-block-info">
              <span className="user-block-name">Hello, Admin</span>
              <span className="user-block-role">Admin</span>
            </div>
          </div>
        </div>
      </Collapse>
    );
  }
}

interface MapStateToProps {
  showUserBlock: boolean;
}

const mapStateToProps = (state: ApplicationState): MapStateToProps => ({ showUserBlock: state.settings.showUserBlock });

export default connect(mapStateToProps)(SidebarUserBlock);
