import { TrustAction, TrustActionTypes, TrustState } from '../../models/trust-model';
import { getTrustStorage } from '../../utils/storage-utils';

const initialState: TrustState = { trusts: [], selectedTrust: getTrustStorage() || undefined };

const trustReducer = (state = initialState, action: TrustAction): TrustState => {
  switch (action.type) {
    case TrustActionTypes.TRUST_SET:
      if (!action.payload.selectedTrust) {
        return { ...state, trusts: action.payload.trusts };
      }

      return { ...state, trusts: action.payload.trusts, selectedTrust: action.payload.selectedTrust };

    case TrustActionTypes.TRUST_SELECTED:
      return { ...state, selectedTrust: action.payload.selectedTrust };

    case TrustActionTypes.AUTH_LOGOUT:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export { trustReducer };
