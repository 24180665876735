import './Vendor';
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss';
import './styles/custom.scss';
import 'react-toastify/dist/ReactToastify.css';

import React, { Component, ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import io from 'socket.io-client';

import SocketContext from './context/socket-context';
import Routes from './routes/Routes';
// App Routes
import { defineToRadians } from './utils/core-utils';

class App extends Component {
  constructor(props = {}) {
    super(props);
    defineToRadians();
  }

  serverURL = process.env.REACT_APP_API_URL || 'http://localhost:4200';

  socket = io(this.serverURL, {
    path: '/socket.io',
    transports: ['websocket'],
  });

  render(): ReactNode {
    // specify base href from env variable 'REACT_APP_PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    const basename = process.env.NODE_ENV === 'development' ? '/' : process.env.REACT_APP_PUBLIC_URL || '/';
    return (
      <SocketContext.Provider value={this.socket}>
        <BrowserRouter basename={basename}>
          <Routes />
        </BrowserRouter>
        <div style={{ zIndex: 500, position: 'relative' }}>
          <ToastContainer position="bottom-center" />
        </div>
      </SocketContext.Provider>
    );
  }
}

export default App;
