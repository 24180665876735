import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { ProfileCollection, UpdateProfileRequest } from '../models/profile-model';
import { StudentToUpdateProfile } from '../models/student-model';
import { store } from '../store/store';

interface ProfileService {
  forgotPassword: (email: string) => Promise<APIResponse>;
  resetPassword: (token: string, password: string) => Promise<APIResponse>;
  getProfiles: () => Promise<ProfileCollection[]>;
  getProfileDetailsById: (profileId: string) => Promise<APIResponse<StudentToUpdateProfile[]>>;
  updateProfile: (profileDetails: UpdateProfileRequest) => Promise<APIResponse>;
}

const profileService = (httpClient: AxiosInstance): ProfileService => {
  return {
    forgotPassword: async (email: string) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/profiles/forgot-password', { email });

        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed' };
      }
    },

    resetPassword: async (token: string, password: string) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/profiles/reset-password', { token, password });

        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Token invalid' };
      }
    },

    getProfiles: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id, userId: authData.userDetails.userId };

        const res: AxiosResponse<ProfileCollection[]> = await httpClient.get('/profiles', { params });
        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    getProfileDetailsById: async (profileId) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return { status: false, message: 'Token invalid' };
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id, userId: authData.userDetails.userId };

        const res: AxiosResponse<APIResponse<StudentToUpdateProfile[]>> = await httpClient.get(
          '/profiles/' + profileId,
          {
            params,
          },
        );
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Token invalid' };
      }
    },

    updateProfile: async (profileDetails) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return { status: false, message: 'Token invalid' };
        }

        const data = {
          role: authData.userDetails.role,
          trustId: selectedTrust._id,
          userId: authData.userDetails.userId,
          ...profileDetails,
        };

        const res: AxiosResponse<APIResponse> = await httpClient.put('/profiles/' + profileDetails._id, data);
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Token invalid' };
      }
    },
  };
};

export default profileService;
