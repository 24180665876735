import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import {
  CreateMultiRouteRequest,
  RouteData,
  RouteForm,
  RouteGroupByRouteName,
  RoutesWithStops,
  RouteUpdateRequest,
} from '../models/route-model';
import { Stop } from '../models/stop-model';
import { StudentsRoutes } from '../models/student-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';
import { ParsedImportRouteData, RouteUploadResponse } from './../models/route-model';

interface RouteService {
  getRoutes: () => Promise<RouteGroupByRouteName[]>;
  getStopsByRoutes: (routeId: string | string[] | null) => Promise<RoutesWithStops | null>;
  getRoutesList: () => Promise<RouteData[]>;
  getStudentsRoute: (routeId: string) => Promise<StudentsRoutes>;
  deleteRoute: (routeId: string) => Promise<APIResponse>;
  createRoute: (data: RouteForm) => Promise<APIResponse>;
  createMultiRoute: (data: CreateMultiRouteRequest) => Promise<APIResponse>;
  reorderRoute: (routeId: string, data: RouteUpdateRequest, initialData: Stop[]) => Promise<APIResponse>;
  updateRoute: (routeId: string, data: RouteForm) => Promise<APIResponse>;
  importRoutes: (fileData: FormData) => Promise<RouteUploadResponse>;
  addBulkRoutesOnImport: (data: ParsedImportRouteData[], key: number | string) => Promise<APIResponse>;
}

const errorResponse: APIResponse = {
  status: false,
};

const routeService = (httpClient: AxiosInstance): RouteService => {
  return {
    getRoutes: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return [];
        }

        const res: AxiosResponse<RouteGroupByRouteName[]> = await httpClient.get(
          '/students/route-details/' + selectedTrust._id,
        );

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    getRoutesList: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return [];
        }

        const params = { id: selectedTrust._id, role: 'admin' };

        const res: AxiosResponse<RouteData[]> = await httpClient.post('/routes/route-list', params);

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    createRoute: async (formData) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        formData.trust = selectedTrust._id;
        const diff = jsonDifference(formData);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/routes', { ...formData, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    createMultiRoute: async (data: CreateMultiRouteRequest) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const diff = jsonDifference(data);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/routes/multi-creator', { ...data, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updateRoute: async (routeId, formData) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        formData.trust = selectedTrust._id;
        const diff = jsonDifference({ ...formData, routeId });
        const res: AxiosResponse<APIResponse> = await httpClient.post('/routes/update/' + routeId, {
          ...formData,
          diff,
        });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    getStopsByRoutes: async (routeId) => {
      try {
        const res: AxiosResponse<RoutesWithStops> = await httpClient.get('/routes/' + routeId, {
          params: { checkpoint: true },
        });

        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    reorderRoute: async (routeId, data, initialStopData) => {
      try {
        const initialData: RouteUpdateRequest = { ...data, data: [] };
        initialStopData.forEach((stop) => {
          if (stop._id) initialData.data.push(stop._id);
        });

        const diff = jsonDifference({ ...data, routeId }, { ...initialData, routeId });
        const res: AxiosResponse<APIResponse> = await httpClient.post('/routes/reorder/' + routeId, { ...data, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    getStudentsRoute: async (routeId: string) => {
      try {
        const res: AxiosResponse<StudentsRoutes> = await httpClient.get('/students/route/' + routeId);

        return res.data;
      } catch (err) {
        console.log(err);
        const errorResponse: StudentsRoutes = {
          studentsDropoff: [],
          studentsPickup: [],
        };
        return errorResponse;
      }
    },

    deleteRoute: async (routeId) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/routes/delete/' + routeId);

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    importRoutes: async (data: FormData) => {
      const errorResponse: RouteUploadResponse = { error: 'Upload failed', filePath: '', resultData: null };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<RouteUploadResponse> = await httpClient.post('/routes/import', data);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    addBulkRoutesOnImport: async (data, key) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/routes/create-list', { data, key });
        return res.data;
      } catch (err) {
        return { status: false, result: data };
      }
    },
  };
};

export default routeService;
