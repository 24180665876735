import React, { ComponentType, FC, memo } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';

import { LoginData } from '../../models/auth-model';

export interface PrivateRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ComponentType<any>;
  auth?: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Comp, auth, ...rest }) => {
  if (!auth) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  return <Route {...rest} render={(props) => <Comp {...props} />} />;
};

const mapStateToProps = (state: { auth: LoginData }) => {
  return {
    auth: state.auth.tokenDetails ? true : false,
  };
};

export default connect(mapStateToProps)(
  memo(PrivateRoute, (prevProps, nextProps) => {
    return prevProps === nextProps;
  }),
);
