import React, { FC } from 'react';

import Header from './Header';
import Sidebar from './Sidebar';
export interface BaseProps {
  test?: string;
}

const Base: FC<BaseProps> = (props) => {
  return (
    <div className="wrapper">
      {window.location.pathname === '/simulate-session' ? <Header hideSidebarIcon={true} /> : <Header />}
      {window.location.pathname !== '/simulate-session' && <Sidebar />}
      {window.location.pathname === '/simulate-session' ? (
        <section style={{ marginTop: '45px', padding: '0px' }}>{props.children}</section>
      ) : (
        <section className="section-container">{props.children}</section>
      )}
    </div>
  );
};

export default Base;
