import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { Trust, TrustDetails, UpdateBatchTypeRequest } from '../models/trust-model';
import { jsonDifference } from '../utils/core-utils';
import { BatchTypeRequest } from './../models/trust-model';

interface TrustService {
  getTrusts: () => Promise<Trust[]>;
  createTrust: (trust: TrustDetails) => Promise<APIResponse>;
  updateTrust: (trust: TrustDetails, initialData: TrustDetails) => Promise<APIResponse>;
  deleteTrust: (trustId: string) => Promise<APIResponse>;
  getBatchTypes: (trust: string) => Promise<string[]>;
  addBatchType: (batchTypeDetails: BatchTypeRequest) => Promise<APIResponse>;
  updateBatchType: (batchTypeDetails: UpdateBatchTypeRequest) => Promise<APIResponse>;
  deleteBatchType: (batchTypeDetails: BatchTypeRequest) => Promise<APIResponse>;
}

const errorResponse: APIResponse = {
  status: false,
  message: 'Sorry for the interruption',
};

const trustService = (httpClient: AxiosInstance): TrustService => {
  return {
    getTrusts: async () => {
      try {
        const res: AxiosResponse<Trust[]> = await httpClient.get('/trusts');

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    createTrust: async (trust) => {
      try {
        const diff = jsonDifference(trust);
        const res: AxiosResponse<APIResponse> = await httpClient.post('trusts/addtrust', { ...trust, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    updateTrust: async (trust, initialData) => {
      try {
        const diff = jsonDifference(trust, initialData);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/trusts/updateTrust', { ...trust, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    deleteTrust: async (trustId) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.delete('trusts/' + trustId);

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    getBatchTypes: async (trustId) => {
      try {
        const res: AxiosResponse<string[]> = await httpClient.get('trusts/batch-type', { params: { trustId } });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    updateBatchType: async (batchDetails) => {
      try {
        const diff = jsonDifference({ batchType: batchDetails.batchType }, { batchType: batchDetails.oldBatchType });
        const res: AxiosResponse<APIResponse> = await httpClient.put('trusts/batch-type', { ...batchDetails, diff });
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    addBatchType: async (batchDetails) => {
      try {
        const diff = jsonDifference(batchDetails);
        const res: AxiosResponse<APIResponse> = await httpClient.post('trusts/batch-type', { ...batchDetails, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    deleteBatchType: async (batchTypeDetails) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.delete('trusts/batch-type', {
          params: batchTypeDetails,
        });
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },
  };
};

export default trustService;
