import { Action } from 'redux';

export enum AuthActionType {
  AUTH_LOADING = 'LOADING',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  AUTH_FAILED = 'AUTH_FAILED',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
}

export interface TokenDetails {
  refreshToken: string;
  authToken: string;
  expiry: number;
}

export interface UserDetails {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  userId: string;
  routeIds?: { pickup: string[]; dropoff: string[] };
}
export interface AuthState extends LoginData {
  loading: boolean;
  error?: string;
}

export interface LoginData {
  tokenDetails?: TokenDetails;
  userDetails?: UserDetails;
  error?: string;
}

export interface AuthAction extends Action {
  payload: LoginData;
  type: AuthActionType;
}

export interface Profile {
  _id: string;
  userId: string;
  created: string;
  rating: number;
  ratingInterval: number;
  hasRated: number;
  isTest: number;
  students: string[];
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  trust: TrustCollection;
}

export interface TrustCollection {
  _id: string;
  trustCode: string;
  trustName: string;
  city: string;
}

export interface LoginResponse {
  userDetails: Profile;
  tokenDetails: TokenDetails;
  msg: string;
  status: string;
  error?: string;
}
