import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { CovidModel, UpdateRequest } from '../models/covid-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';

interface CovidService {
  getData: (dateFilter: string) => Promise<CovidModel | null>;
  updateTemperature: (data: UpdateRequest) => Promise<APIResponse>;
}

const errorResponse: APIResponse = {
  status: false,
};

const covidService = (httpClient: AxiosInstance): CovidService => {
  return {
    getData: async (dateFilter: string) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return null;
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id, date: dateFilter };
        const res: AxiosResponse<CovidModel> = await httpClient.get('/covid', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    updateTemperature: async (data) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        data.trust = selectedTrust._id;
        const diff = jsonDifference(data);
        const res: AxiosResponse<APIResponse> = await httpClient.put('/covid', { ...data, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },
  };
};

export default covidService;
