import { applyMiddleware, createStore } from 'redux';

import { AuthState } from '../models/auth-model';
import { SettingState, ThemeState } from './actions/actions';
import middlewares from './middlewares/middlewares';
import { updateTheme } from './middlewares/themes.middleware';
import { persistedState, saveState } from './persisted.store';
import reducers from './reducers/reducers';

export interface ApplicationState {
  settings: SettingState;
  theme: ThemeState;
  auth: AuthState;
}

function configureStore() {
  const store = createStore(
    reducers,
    persistedState, // second argument overrides the initial state
    applyMiddleware(...middlewares),
  );

  // add a listener that will be invoked on any state change
  store.subscribe(() => {
    saveState(store.getState());
  });

  // Update the initial theme
  updateTheme(store.getState());

  return store;
}

const store = configureStore();

export { store };
