/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from 'reactstrap';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import SocketContext from '../../../context/socket-context';
import { LoginData, TokenDetails, UserDetails } from '../../../models/auth-model';
import { PanicResponse } from '../../../models/panic-model';
import { Permissions } from '../../../models/permission-model';
import { SocketEvents } from '../../../models/socket-model';
import { Trust, TrustState } from '../../../models/trust-model';
import {
  logout,
  permissions,
  setSelectedTrust,
  SettingAction,
  toggleSetting,
  trustList,
} from '../../../store/actions/actions';

interface HeaderProps {
  toggleSetting: (name: string) => SettingAction;
  signOut: () => void;
  getTrusts: () => void;
  getPermissions: () => void;
  setSelectedTrust: (id: string, trusts: Trust[]) => void;
  tokenDetails?: TokenDetails;
  userDetails?: UserDetails;
  trusts: Trust[];
  selectedTrust?: Trust;
  socket?: SocketIOClient.Socket;
  hideSidebarIcon?: boolean;
}

export interface HeaderState {
  selectedTrust?: Trust;
  trusts: Trust[];
}

interface PanicToastProps {
  children: ReactNode;
}

const PanicToast = (props: PanicToastProps) => (
  <Link style={{ color: '#fff' }} to="/manage-panics">
    {props.children}
  </Link>
);

class Header extends Component<HeaderProps, HeaderState> {
  componentDidMount() {
    if (!this.props.tokenDetails) {
      return;
    }

    this.props.getTrusts();
    this.props.getPermissions();
    this.socketListener();
  }

  socketListener() {
    if (!this.props.socket) {
      return;
    }

    this.props.socket.on(SocketEvents.PANIC_EVENT, (data: PanicResponse) => {
      toast.dismiss();
      toast.error(() => (
        <PanicToast>
          <span>Panic alert on device: {data.did}</span>
        </PanicToast>
      ));
    });
  }

  toggleUserBlock = (e: React.MouseEvent): void => {
    e.preventDefault();
    this.props.toggleSetting('showUserBlock');
  };

  toggleCollapsed = (e: React.MouseEvent): void => {
    e.preventDefault();
    this.props.toggleSetting('isCollapsed');
    this.resize();
  };

  toggleAside = (e: React.MouseEvent): void => {
    e.preventDefault();
    this.props.toggleSetting('asideToggled');
  };

  resize(): void {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    document.dispatchEvent(event);
  }

  logoutHandler: React.MouseEventHandler = () => {
    this.props.signOut();
  };

  trustChangeHandler: any = (e: any): void => {
    this.props.setSelectedTrust(e.target.value, this.props.trusts);
  };

  render(): ReactNode {
    return (
      <header className="topnavbar-wrapper">
        <nav className="navbar topnavbar">
          <div className="navbar-header">
            <a className="navbar-brand" href="">
              <div className="brand-logo">
                <img className="img-fluid" src="img/logo.png" alt="Trackify" />
                <span className="text-white ml-2">Trackify</span>
              </div>
              <div className="brand-logo-collapsed">
                <img className="img-fluid" src="img/logo.png" alt="Trackify" />
              </div>
            </a>
          </div>

          {!this.props.hideSidebarIcon && (
            <ul className="navbar-nav mr-auto flex-row">
              <li className="nav-item">
                <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={this.toggleCollapsed}>
                  <em className="fas fa-bars"></em>
                </a>

                <a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                  <em className="fas fa-bars"></em>
                </a>
              </li>

              <li className="nav-item d-none d-md-block">
                <a className="nav-link" href="" onClick={this.toggleUserBlock}>
                  <em className="icon-user"></em>
                </a>
              </li>
            </ul>
          )}

          <ul className="navbar-nav flex-row">
            <li>
              <Input
                className="ml-1"
                type="select"
                name="trusts"
                id="trusts"
                defaultValue={this.props.selectedTrust ? this.props.selectedTrust._id : ''}
                style={{ marginTop: '10px', borderRadius: '0px' }}
                disabled={this.props.trusts.length === 1}
                onChange={this.trustChangeHandler.bind(this)}
              >
                {this.props.trusts.map((item) => {
                  return (
                    <option key={item._id} value={item._id}>
                      {item.trustName}
                    </option>
                  );
                })}
              </Input>
            </li>
            <li className="nav-item">
              <a className="nav-link" title="logout" style={{ cursor: 'pointer' }} onClick={this.logoutHandler}>
                <strong>
                  <em className="icon-logout" aria-hidden="true" /> Logout
                </strong>
              </a>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = (state: { auth: LoginData; trust: TrustState; permission: Permissions }) => {
  return {
    tokenDetails: state.auth.tokenDetails,
    userDetails: state.auth.userDetails,
    trusts: state.trust.trusts || [],
    selectedTrust: state.trust.selectedTrust,
    permissions: state.permission.permissions,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<void, never, Action>) => {
  return {
    toggleSetting: bindActionCreators(toggleSetting, dispatch),
    signOut: bindActionCreators(logout, dispatch),
    getTrusts: bindActionCreators(trustList, dispatch),
    getPermissions: bindActionCreators(permissions, dispatch),
    setSelectedTrust: bindActionCreators(setSelectedTrust, dispatch),
  };
};

const HeaderWithSockets = (props: HeaderProps) => (
  <SocketContext.Consumer>{(socket) => <Header {...props} socket={socket} />}</SocketContext.Consumer>
);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWithSockets);
