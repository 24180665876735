import { AxiosInstance, AxiosResponse } from 'axios';

import { AWSFileData } from '../models/aws-model';
import { APIResponse } from '../models/common-model';

interface CommonService {
  generateUploadUrl: (data: AWSFileData) => Promise<APIResponse<{ uploadUrl: string }>>;
}

const commonService = (httpClient: AxiosInstance): CommonService => {
  return {
    generateUploadUrl: async (data) => {
      try {
        const res: AxiosResponse<APIResponse<{ uploadUrl: string }>> = await httpClient.post('/aws/generate-url', data);

        return res.data;
      } catch (err) {
        return { status: false, message: 'Token invalid' };
      }
    },
  };
};

export default commonService;
