import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { DeveloperApi } from '../models/developer-api-model';
import { jsonDifference } from '../utils/core-utils';
import { DeveloperApiCollection } from './../models/developer-api-model';

interface DeveloperApiService {
  getDeveloperApiList: () => Promise<DeveloperApiCollection[]>;
  saveDeveloperApi: (data: DeveloperApi) => Promise<APIResponse>;
  updateDeveloperApi: (data: DeveloperApiCollection, oldData: DeveloperApiCollection) => Promise<APIResponse>;
  updateStatus: (developerApiId: string, isActive: boolean) => Promise<APIResponse>;
  getApiKeyById: (id: string) => Promise<APIResponse>;
  deleteApi: (id: string) => Promise<APIResponse>;
}

const developerApiService = (httpClient: AxiosInstance): DeveloperApiService => {
  return {
    getDeveloperApiList: async () => {
      try {
        const res: AxiosResponse<DeveloperApiCollection[]> = await httpClient.get('/manage-developer');

        return res.data;
      } catch (err) {
        return [];
      }
    },

    saveDeveloperApi: async (data: DeveloperApi) => {
      try {
        const diff = jsonDifference(data);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/manage-developer', { ...data, diff });

        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to save' };
      }
    },

    updateDeveloperApi: async (data, initialData) => {
      try {
        const diff = jsonDifference(data, initialData);
        const res: AxiosResponse<APIResponse> = await httpClient.put('/manage-developer/' + data._id, {
          ...data,
          diff,
        });

        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to update' };
      }
    },

    updateStatus: async (developerApiId: string, isActive: boolean) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/manage-developer/update/status', {
          developerApiId,
          isActive,
        });

        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to update' };
      }
    },

    getApiKeyById: async (id: string) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.get('/manage-developer/' + id);

        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get API key' };
      }
    },

    deleteApi: async (id: string) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.delete('/manage-developer/' + id);

        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to delete' };
      }
    },
  };
};

export default developerApiService;
