import { Obj } from './models/core-model';
import { ISidebarMenu, SidebarMenuItem } from './models/sidebar.model';
import { routes } from './routes';

export const getSideBarMenu = (permissions: Obj): SidebarMenuItem[] => {
  const sidebarMenu: SidebarMenuItem[] = [];
  routes.forEach((route) => {
    if (!Array.isArray(route.properties)) {
      const key = route.properties.showPermission as unknown as keyof typeof permissions;
      if (!permissions[key]) {
        return;
      }

      sidebarMenu.push({ ...route.properties.menu, path: route.path });
      return;
    }

    const sidebarSubMenu: ISidebarMenu = [];
    route.properties.forEach((subRoute) => {
      const key = subRoute.showPermission as unknown as keyof typeof permissions;
      if (!permissions[key]) {
        return;
      }

      sidebarSubMenu.push({ ...subRoute.subMenu, path: `${route.path}${subRoute.path}` });
    });

    if (!sidebarSubMenu.length) {
      return;
    }

    sidebarMenu.push({ ...route.menu, path: route.path, submenu: sidebarSubMenu });
  });

  return sidebarMenu;
};
