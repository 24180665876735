import { getSideBarMenu } from '../../Menu';
import { PermissionAction, PermissionActionTypes } from '../../models/permission-model';
import { Permissions } from './../../models/permission-model';

const initialValue: Permissions = { permissions: {}, sideBarMenu: [] };
const permissionReducer = (state = initialValue, action: PermissionAction): Permissions => {
  switch (action.type) {
    case PermissionActionTypes.PERMISSION:
      const sideBarMenu = getSideBarMenu(action.payload.permissions);
      return { ...state, permissions: action.payload.permissions, sideBarMenu };

    case PermissionActionTypes.AUTH_LOGOUT:
      return { ...state, ...initialValue };

    default:
      return state;
  }
};

export { permissionReducer };
