import { lazy } from 'react';

const ManageRoutes = lazy(() => import('../pages/ManageRoutes'));
const ManageStudents = lazy(() => import('../pages/ManageStudents'));
const ManageProfiles = lazy(() => import('../pages/ManageProfiles'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const ManageStaffs = lazy(() => import('../pages/ManageStaffs'));
const ManageStops = lazy(() => import('../pages/ManageStops'));
const ManageStudentsRFID = lazy(() => import('../pages/manage-rfid/ManageStudentsRFID'));
const ManageRoutesRFID = lazy(() => import('../pages/manage-rfid/ManageRoutesRFID'));
const ManageDevices = lazy(() => import('../pages/ManageDevices'));
const ManageRouteBuilderRFID = lazy(() => import('../pages/manage-rfid/ManageRouteBuilderRFID'));
const ManageStaffRFID = lazy(() => import('../pages/manage-rfid/ManageStaffRFID'));
const ManageDeviceRFID = lazy(() => import('../pages/manage-rfid/ManageDevicesRFID'));
const ManageSchools = lazy(() => import('../pages/ManageSchools'));
const ManageTrusts = lazy(() => import('../pages/ManageTrusts'));
const ManagePanics = lazy(() => import('../pages/ManagePanics'));
const RegistrationStatus = lazy(() => import('../pages/RegistrationStatus'));
const ManageParentAbout = lazy(() => import('../pages/ManageParentalAbout'));
const ManageParentInfos = lazy(() => import('../pages/ManageParentInfos'));
const ManageDeveloperApi = lazy(() => import('../pages/ManageDeveloperApi'));
const UsersManagement = lazy(() => import('../pages/UsersManagement'));
const Logs = lazy(() => import('../pages/Logs'));
const DefectiveSessionLogs = lazy(() => import('../pages/DefectiveSessionLogs'));

const SimulateSession = lazy(() => import('../components/manage-logs/SimulateSession'));
const ManageRoles = lazy(() => import('../components/user-management/role-management/ManageRoles'));
const ImportDevices = lazy(() => import('../components/manage-devices/ImportDevices'));
const ManageAccounts = lazy(() => import('../pages/ManageAccounts'));
const AddStop = lazy(() => import('../components/manage-stops/AddStop'));
const AddStudent = lazy(() => import('../components/manage-students/AddStudent'));
const ImportStops = lazy(() => import('../components/manage-stops/ImportStops'));
const ImportStudents = lazy(() => import('../components/manage-students/ImportStudent'));
const AddRoute = lazy(() => import('../components/manage-routes/AddRoutes'));
const MultiRouteCreator = lazy(() => import('../components/manage-routes/MultiRouteCreator'));
const ImportRoutes = lazy(() => import('../components/manage-routes/ImportRoutes'));
const AddRFID = lazy(() => import('../components/manage-rfids/student-rfids/AddRFID'));
const StopCollection = lazy(() => import('../components/manage-stops/StopCollection'));
const GroupAssignRFID = lazy(() => import('../components/manage-rfids/student-rfids/GroupAssignRFID'));
const ImportStudentRFIDs = lazy(() => import('../components/manage-rfids/student-rfids/ImportStudentRFIDs'));
const ManageSections = lazy(() => import('../components/manage-school/manage-sections/ManageSections'));
const ManageStandards = lazy(() => import('../components/manage-school/manage-standards/ManageStandards'));
const ManageDivisions = lazy(() => import('../components/manage-school/manage-divisions/ManageDivisions'));
const ImportSections = lazy(() => import('../components/manage-school/ImportSections'));
const ManageBatchTypes = lazy(() => import('../components/manage-school/manage-batch-types/ManageBatchTypes'));
const AddTrust = lazy(() => import('../components/manage-trusts/AddTrust'));
const EditStops = lazy(() => import('../components/manage-routes/EditStops'));
const AddUser = lazy(() => import('../components/manage-accounts/AddUser'));
const Covid = lazy(() => import('../pages/Covid'));
const ManageParentInfo = lazy(() => import('../components/manage-parent-infos/ManageParentInfo'));
const ImportRouteRFIDs = lazy(() => import('../components/manage-rfids/route-rfids/ImportRouteRFIDs'));
const ImportDeviceRFIDs = lazy(() => import('../components/manage-rfids/device-rfids/ImportDeviceRFIDs'));
const ImportStaffRFIDs = lazy(() => import('../components/manage-rfids/staff-rfids/ImportStaffRFIDs'));
const AddRole = lazy(() => import('../components/user-management/role-management/AddRole'));
const UserManagementAddUser = lazy(() => import('../components/user-management/AddUser'));

export const routes = [
  {
    path: '/dashboard',
    component: Dashboard,
    properties: {
      showPermission: 'dashboard.read',
      menu: {
        name: 'Dashboard',
        icon: 'icon-grid',
        translate: 'sidebar.nav.DASHBOARD',
      },
    },
  },
  {
    path: '/logs',
    component: Logs,
    properties: {
      showPermission: 'log.read',
      menu: {
        name: 'Logs',
        icon: 'fa fa-history',
        translate: 'sidebar.nav.LOGS',
      },
    },
  },
  {
    path: '/defective-sessions',
    component: DefectiveSessionLogs,
    properties: {
      showPermission: 'log.read',
      menu: {
        name: 'Defective Sessions',
        icon: 'fa fa-exclamation',
        translate: 'sidebar.nav.DEFECTIVE',
      },
    },
  },
  {
    path: '/manage-infos',
    component: ManageParentInfos,
    properties: {
      showPermission: 'parent_info.read',
      menu: {
        name: 'Info',
        icon: 'fa fa-bell',
        translate: 'sidebar.nav.INFO',
      },
    },
  },
  {
    path: '/manage-staffs',
    component: ManageStaffs,
    properties: {
      showPermission: 'staff.read',
      menu: {
        name: 'Staffs',
        icon: 'fas fa-bus',
        translate: 'sidebar.nav.STAFFS',
      },
    },
  },
  {
    path: '/manage-devices',
    component: ManageDevices,
    properties: {
      showPermission: 'device.read',
      menu: {
        name: 'Devices',
        icon: 'fas fa-satellite-dish',
        translate: 'sidebar.nav.DEVICES',
      },
    },
  },
  {
    path: '/manage-stops',
    component: ManageStops,
    properties: {
      showPermission: 'stop.read',
      menu: {
        name: 'Stops',
        icon: 'icon-location-pin',
        translate: 'sidebar.nav.STOPS',
      },
    },
  },
  {
    path: '/manage-students',
    component: ManageStudents,
    properties: {
      showPermission: 'student.read',
      menu: {
        name: 'Students',
        path: '/manage-students',
        icon: 'icon-graduation',
        translate: 'sidebar.nav.STUDENTS',
      },
    },
  },
  {
    path: '/manage-profiles',
    component: ManageProfiles,
    properties: {
      showPermission: 'profile.read',
      menu: {
        name: 'Profiles',
        path: '/manage-profiles',
        icon: 'fa fa-users',
        translate: 'sidebar.nav.PROFILES',
      },
    },
  },
  {
    path: '/manage-routes',
    component: ManageRoutes,
    properties: {
      showPermission: 'route.read',
      menu: {
        name: 'Routes',
        path: '/manage-routes',
        icon: 'fa fa-route',
        translate: 'sidebar.nav.ROUTES',
      },
    },
  },
  {
    path: '/manage-rfids',
    menu: {
      name: 'RFIDs',
      icon: 'fa fa-id-card',
      translate: 'sidebar.nav.RFIDS',
    },
    properties: [
      {
        path: '/students',
        showPermission: 'student_rfid.read',
        component: ManageStudentsRFID,
        subMenu: {
          name: 'Student RFIDs',
          icon: 'icon-credit-card',
          translate: 'sidebar.nav.RFIDS',
        },
      },
      {
        path: '/routes',
        component: ManageRoutesRFID,
        showPermission: 'route_rfid.read',
        subMenu: {
          name: 'Route RFIDs',
          icon: 'icon-credit-card',
          translate: 'sidebar.nav.RFIDS',
        },
      },
      {
        path: '/route-builder',
        component: ManageRouteBuilderRFID,
        showPermission: 'route_builder_rfid.read',
        subMenu: {
          name: 'Route Builder RFIDs',
          icon: 'icon-credit-card',
          translate: 'sidebar.nav.RFIDS',
        },
      },
      {
        path: '/staff',
        component: ManageStaffRFID,
        showPermission: 'staff_rfid.read',
        subMenu: {
          name: 'Staff RFIDs',
          icon: 'icon-credit-card',
          translate: 'sidebar.nav.RFIDS',
        },
      },
      {
        path: '/device',
        component: ManageDeviceRFID,
        showPermission: 'device_rfid.read',
        subMenu: {
          name: 'Device RFIDs',
          icon: 'icon-credit-card',
          translate: 'sidebar.nav.RFIDS',
        },
      },
    ],
  },
  {
    path: '/manage-schools',
    component: ManageSchools,
    properties: {
      showPermission: 'school.read',
      menu: {
        name: 'Schools',
        icon: 'fa fa-school',
        translate: 'sidebar.nav.SCHOOLS',
      },
    },
  },
  {
    path: '/manage-trusts',
    component: ManageTrusts,
    properties: {
      showPermission: 'trust.read',
      menu: {
        name: 'Trusts',
        icon: 'fas fa-university',
        translate: 'sidebar.nav.TRUSTS',
      },
    },
  },
  {
    path: '/manage-panics',
    component: ManagePanics,
    properties: {
      showPermission: 'panic.read',
      menu: {
        name: 'Panics',
        icon: 'fas fa-exclamation-triangle',
        translate: 'sidebar.nav.STUDENTS',
      },
    },
  },
  {
    path: '/reg-status',
    component: RegistrationStatus,
    properties: {
      showPermission: 'registration_status.read',
      menu: {
        name: 'Registration Status',
        icon: 'icon-graduation',
        translate: 'sidebar.nav.STUDENTS',
      },
    },
  },
  {
    path: '/parent/policy',
    component: ManageParentAbout,
    properties: {
      showPermission: 'policy.read',
      menu: {
        name: 'Policy',
        icon: 'fa fa-file-signature ',
        translate: 'sidebar.nav.ABOUT',
      },
    },
  },
  {
    path: '/developer-api',
    component: ManageDeveloperApi,
    properties: {
      showPermission: 'developer_api.read',
      menu: {
        name: 'Developer API',
        icon: 'fab fa-dev',
        translate: 'sidebar.nav.DEV_API',
      },
    },
  },
  {
    path: '/user-management',
    component: UsersManagement,
    properties: {
      showPermission: 'user_management.read',
      menu: {
        name: 'User Management',
        icon: 'fa fa-user',
        translate: 'sidebar.nav.DEV_API',
      },
    },
  },
];

export const nonMenuRoutes = [
  {
    path: '/manage-devices/import',
    component: ImportDevices,
    properties: { showPermission: 'device.write' },
  },
  // {
  //   path: '/manage-attendants',
  //   component: ManageAttendants,
  //   properties: {
  //     showPermission: 'device.write',
  //   },
  // },
  {
    path: '/manage-accounts',
    component: ManageAccounts,
    properties: { showPermission: 'accounts.read' },
  },
  {
    path: '/add-user',
    component: AddUser,
    properties: { showPermission: 'user.write' },
  },
  {
    path: '/add-stop',
    component: AddStop,
    properties: { showPermission: 'stop.write' },
  },
  {
    path: '/stop-collection',
    component: StopCollection,
    properties: { showPermission: 'stop.stop_collection' },
  },
  {
    path: '/manage-stops/import',
    component: ImportStops,
    properties: { showPermission: 'stop.write' },
  },
  {
    path: '/add-student',
    component: AddStudent,
    properties: { showPermission: 'student.write' },
  },
  {
    path: '/import-students',
    component: ImportStudents,
    properties: { showPermission: 'student.write' },
  },
  {
    path: '/manage-routes/import',
    component: ImportRoutes,
    properties: { showPermission: 'route.write' },
  },
  {
    path: '/multi-route-creator',
    component: MultiRouteCreator,
    properties: { showPermission: 'route.write' },
  },
  {
    path: '/manage-rfids/import-route-rfids',
    component: ImportRouteRFIDs,
    properties: { showPermission: 'route_rfid.write' },
  },
  {
    path: '/manage-rfids/import-staff-rfids',
    component: ImportStaffRFIDs,
    properties: { showPermission: 'staff_rfid.write' },
  },
  {
    path: '/manage-rfids/import-device-rfids',
    component: ImportDeviceRFIDs,
    properties: { showPermission: 'device_rfid.write' },
  },
  {
    path: '/add-rfid',
    component: AddRFID,
    properties: { showPermission: 'student_rfid.write' },
  },
  {
    path: '/group-assign-rfid',
    component: GroupAssignRFID,
    properties: { showPermission: 'student_rfid.write' },
  },
  {
    path: '/import-student-rfids',
    component: ImportStudentRFIDs,
    properties: { showPermission: 'student_rfid.write' },
  },
  {
    path: '/manage-schools/manage-sections',
    component: ManageSections,
    properties: { showPermission: 'school.write' },
  },
  {
    path: '/manage-schools/manage-standards',
    component: ManageStandards,
    properties: { showPermission: 'school.write' },
  },
  {
    path: '/manage-schools/manage-divisions',
    component: ManageDivisions,
    properties: { showPermission: 'school.write' },
  },
  {
    path: '/manage-schools/manage-batch-type',
    component: ManageBatchTypes,
    properties: { showPermission: 'school.write' },
  },
  {
    path: '/manage-schools/import-sections',
    component: ImportSections,
    properties: { showPermission: 'school.write' },
  },
  {
    path: '/add-trust',
    component: AddTrust,
    properties: { showPermission: 'trust.write' },
  },
  {
    path: '/add-route',
    component: AddRoute,
    properties: { showPermission: 'route.write' },
  },
  {
    path: '/manage-routes/edit-stops',
    component: EditStops,
    properties: { showPermission: 'route.write' },
  },
  {
    path: '/covid',
    component: Covid,
    properties: { showPermission: 'covid.read' },
  },
  {
    path: '/manage-infos/info',
    component: ManageParentInfo,
    properties: { showPermission: 'parent_info.write' },
  },
  {
    path: '/user-management/add-user',
    component: UserManagementAddUser,
    properties: { showPermission: 'user_management.write' },
  },
  {
    path: '/user-management/roles',
    component: ManageRoles,
    properties: { showPermission: 'user_management_role.read' },
  },
  {
    path: '/user-management/add-role',
    component: AddRole,
    properties: { showPermission: 'user_management_role.write' },
  },
  {
    path: '/simulate-session/',
    component: SimulateSession,
    properties: { showPermission: 'log.write' },
  },
];
