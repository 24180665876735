import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import {
  Attachment,
  FileTypeAndName,
  InfoFilterData,
  ListParentInfoResponseData,
  ParentInfoCollection,
  ParentInfoCreateRequest,
  ParentInfoUpdateRequest,
} from '../models/info-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';

interface EmailService {
  getInfoList: () => Promise<APIResponse<ListParentInfoResponseData>>;
  addInfo: (data: ParentInfoCreateRequest) => Promise<APIResponse>;
  updateInfo: (data: ParentInfoUpdateRequest, initialData: ParentInfoCollection) => Promise<APIResponse>;
  getInfoById: (infoId: string) => Promise<APIResponse<ParentInfoCollection>>;
  generateUploadUrl: (data: FileTypeAndName) => Promise<APIResponse<{ uploadUrl: string }>>;
  getFilterOptions: () => Promise<InfoFilterData[]>;
  removeAttachedFile: (file: Attachment) => Promise<APIResponse>;
  deleteInfo: (info: ParentInfoCollection) => Promise<APIResponse>;
}

const infoService = (httpClient: AxiosInstance): EmailService => {
  return {
    getInfoList: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return { status: false, message: 'Invalid request' };
        }

        const params = { role: authData.userDetails.role, trustId: selectedTrust._id };
        const res: AxiosResponse<APIResponse<ListParentInfoResponseData>> = await httpClient.get('/info', {
          params,
        });

        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Invalid request' };
      }
    },

    getInfoById: async (infoId) => {
      try {
        const res: AxiosResponse<APIResponse<ParentInfoCollection>> = await httpClient.get('/info/' + infoId);
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Token invalid' };
      }
    },

    addInfo: async (data) => {
      try {
        const diff = jsonDifference(data);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/info', { ...data, diff });

        return res.data;
      } catch (err) {
        return { status: false, message: 'Token invalid' };
      }
    },

    updateInfo: async (data, initialData) => {
      try {
        const diff = jsonDifference(data, initialData);
        const res: AxiosResponse<APIResponse> = await httpClient.put('/info/' + data._id, { ...data, diff });

        return res.data;
      } catch (err) {
        return { status: false, message: 'Token invalid' };
      }
    },

    generateUploadUrl: async (data) => {
      try {
        const res: AxiosResponse<APIResponse<{ uploadUrl: string }>> = await httpClient.post(
          '/info/generate-url',
          data,
        );

        return res.data;
      } catch (err) {
        return { status: false, message: 'Token invalid' };
      }
    },
    getFilterOptions: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = {
          role: authData.userDetails.role,
          id: selectedTrust._id,
          userId: authData.userDetails.userId,
        };
        const res: AxiosResponse<InfoFilterData[]> = await httpClient.get('/info/filter-options', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    removeAttachedFile: async (file) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return { status: false, message: 'Token invalid' };
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<APIResponse> = await httpClient.delete('/info/remove-file/' + file.fileName, {
          params,
        });

        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Token invalid' };
      }
    },
    deleteInfo: async (info) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return { status: false, message: 'Token invalid' };
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<APIResponse> = await httpClient.delete('/info/' + info._id, {
          params,
        });

        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Token invalid' };
      }
    },
  };
};

export default infoService;
