import { AxiosInstance, AxiosResponse } from 'axios';

interface ConnectionService {
  checkConnection: () => Promise<AxiosResponse<unknown>>;
}

const connectionService = (httpClient: AxiosInstance): ConnectionService => {
  return {
    checkConnection: () => {
      return httpClient.get('/ping');
    },
  };
};

export default connectionService;
