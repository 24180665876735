import { AxiosInstance, AxiosResponse } from 'axios';

import {
  DeviceRFIDResponse,
  GroupAssignRFIDResponse,
  ImportResponse,
  ParsedImportDeviceRFIDData,
  ParsedImportRFIDData,
  RFIDList,
  RouteBuilderValidResponse,
  StudentAndRFID,
  StudentsUnderRouteRequest,
  UpdateDeviceRFIDRequest,
  UpdateStudentRFID,
} from '../models/rfid-model';
import { RouteData, UpdateRouteRFIDRequest } from '../models/route-model';
import { StudentUnderRoute } from '../models/student-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';
import { APIResponse } from './../models/common-model';
import { RFIDCollection, RouteRFIDResponse, StaffRFIDResponse, UpdateStaffRFIDRequest } from './../models/rfid-model';

interface RFIDService {
  getRFIDList: (schoolId: string) => Promise<RFIDList[]>;
  getRouteBuilderRFIDList: () => Promise<RouteRFIDResponse[]>;
  getStaffRFIDList: () => Promise<StaffRFIDResponse[]>;
  getDeviceRFIDList: () => Promise<DeviceRFIDResponse[]>;
  getStudentsByRoute: (data: StudentsUnderRouteRequest) => Promise<StudentUnderRoute[]>;
  updateStudentRFID: (data: UpdateStudentRFID) => Promise<APIResponse>;
  importRFID: (data: FormData) => Promise<ImportResponse>;
  addBulkRFID: (data: ParsedImportRFIDData[], key: number | string) => Promise<APIResponse>;
  addBulkRouteRFID: (data: ParsedImportRFIDData[], key: number | string) => Promise<APIResponse>;
  addBulkStaffRFID: (data: ParsedImportRFIDData[], key: number | string) => Promise<APIResponse>;
  addBulkDeviceRFID: (data: ParsedImportDeviceRFIDData[], key: number | string) => Promise<APIResponse>;
  updateStudentsRFIDs: (data: StudentAndRFID[]) => Promise<GroupAssignRFIDResponse[]>;
  deleteRFID: (data: RFIDList) => Promise<APIResponse>;
  verifyRFID: (rfid: string) => Promise<RouteBuilderValidResponse>;
  initiateRFID: (rfid: string) => Promise<boolean>;
  updateRouteRFID: (rfidData: UpdateRouteRFIDRequest, initialValues: RouteData) => Promise<APIResponse<RFIDCollection>>;
  updateRouteBuilderRFID: (
    rfidData: UpdateRouteRFIDRequest,
    initialValues: RouteRFIDResponse,
  ) => Promise<APIResponse<RFIDCollection>>;
  updateStaffRFID: (rfidData: UpdateStaffRFIDRequest, initialData: StaffRFIDResponse) => Promise<APIResponse>;
  updateDeviceRFID: (rfidData: UpdateDeviceRFIDRequest, initialData: DeviceRFIDResponse) => Promise<APIResponse>;
  deleteRFIDDetails: (rfidId: string) => Promise<boolean>;
}

const errorResponse: APIResponse = {
  status: false,
};

const rfidService = (httpClient: AxiosInstance): RFIDService => {
  return {
    getRFIDList: async (schoolId) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = {
          role: authData.userDetails.role,
          id: selectedTrust._id,
          schoolId: schoolId !== 'all' ? schoolId : null,
        };
        const res: AxiosResponse<RFIDList[]> = await httpClient.get('/rfids', { params });

        return res.data;
      } catch (err) {
        return [];
      }
    },
    getRouteBuilderRFIDList: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, trust: selectedTrust._id };
        const res: AxiosResponse<RouteRFIDResponse[]> = await httpClient.get('/rfids/route-builder', { params });

        return res.data;
      } catch (err) {
        return [];
      }
    },
    getStaffRFIDList: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, trust: selectedTrust._id };
        const res: AxiosResponse<StaffRFIDResponse[]> = await httpClient.get('/rfids/staff', { params });

        return res.data;
      } catch (err) {
        return [];
      }
    },

    getDeviceRFIDList: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, trust: selectedTrust._id };
        const res: AxiosResponse<DeviceRFIDResponse[]> = await httpClient.get('/rfids/device', { params });

        return res.data;
      } catch (err) {
        return [];
      }
    },

    getStudentsByRoute: async (params) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }
        params.trustId = selectedTrust._id;
        const res: AxiosResponse<StudentUnderRoute[]> = await httpClient.get('/rfids/studentsbyroute', { params });
        return res.data;
      } catch (err) {
        return [];
      }
    },

    updateStudentRFID: async (data) => {
      try {
        // TODO: Please verify difference
        const diff = { new: { rfid: data.rfid }, old: { rfid: data.oldrfid } };
        const res: AxiosResponse<APIResponse> = await httpClient.post('/rfids', { ...data, diff });
        return res.data;
      } catch (err) {
        return errorResponse;
      }
    },

    importRFID: async (data) => {
      try {
        const res: AxiosResponse<ImportResponse> = await httpClient.post('/rfids/import', data);
        return res.data;
      } catch (err) {
        return { filePath: '', resultData: null };
      }
    },

    addBulkRFID: async (data, key) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/rfids/import-rfids', { data, key });
        return res.data;
      } catch (err) {
        return { status: false, result: data };
      }
    },

    addBulkRouteRFID: async (data, key) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/rfids/route/import-rfids', { data, key });
        return res.data;
      } catch (err) {
        return { status: false, result: data };
      }
    },

    addBulkStaffRFID: async (data, key) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/rfids/staff/import-rfids', { data, key });
        return res.data;
      } catch (err) {
        return { status: false, result: data };
      }
    },

    addBulkDeviceRFID: async (data, key) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/rfids/device/import-rfids', { data, key });
        return res.data;
      } catch (err) {
        return { status: false, result: data };
      }
    },

    updateStudentsRFIDs: async (data) => {
      try {
        const res: AxiosResponse<GroupAssignRFIDResponse[]> = await httpClient.put('/rfids/students', data);
        return res.data;
      } catch (err) {
        return [{ status: false, message: 'Failed to update RFIDs' }];
      }
    },

    deleteRFID: async (deleteData) => {
      try {
        const data = { id: deleteData._id, student: deleteData.student };
        const res: AxiosResponse<APIResponse> = await httpClient.post('rfids/delete', data);
        return res.data;
      } catch (err) {
        return errorResponse;
      }
    },

    verifyRFID: async (rfid) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return { rfidData: null };
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id, rfid };
        const res: AxiosResponse<RouteBuilderValidResponse> = await httpClient.get('/rfids/valid', { params });
        return res.data;
      } catch (err) {
        return { rfidData: null };
      }
    },

    initiateRFID: async (rfid) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return false;
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id, rfid };

        const res: AxiosResponse<boolean> = await httpClient.get('/rfids/initiate', { params });
        return res.data;
      } catch (err) {
        return false;
      }
    },

    updateRouteRFID: async (rfidData, initialData) => {
      const diff = jsonDifference(rfidData, initialData);
      const res: AxiosResponse<APIResponse<RFIDCollection>> = await httpClient.post(`/rfids/route`, {
        ...rfidData,
        diff,
      });
      return res.data;
    },

    updateRouteBuilderRFID: async (rfidData, initialData) => {
      const diff = jsonDifference(rfidData, initialData);
      const res: AxiosResponse<APIResponse<RFIDCollection>> = await httpClient.post(`/rfids/route-builder`, {
        ...rfidData,
        diff,
      });
      return res.data;
    },

    updateStaffRFID: async (rfidData, initialData) => {
      const diff = jsonDifference(rfidData, initialData);
      const res: AxiosResponse<APIResponse> = await httpClient.post(`/rfids/staff`, { ...rfidData, diff });
      return res.data;
    },

    updateDeviceRFID: async (rfidData, initialData) => {
      const diff = jsonDifference(rfidData, initialData);
      const res: AxiosResponse<APIResponse> = await httpClient.post(`/rfids/device`, { ...rfidData, diff });
      return res.data;
    },

    deleteRFIDDetails: async (rfidId: string) => {
      const res: AxiosResponse<boolean> = await httpClient.delete(`/rfids/data/${rfidId}`);
      return res.data;
    },
  };
};

export default rfidService;
