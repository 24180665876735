import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { RouteBuilderSessionCollection } from '../models/route-builder-model';
import { BuilderRouteDetailsRequest } from '../models/route-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';
import { NewStop } from './../models/route-builder-model';

interface RouteBuilderService {
  getRouteBuilder: () => Promise<RouteBuilderSessionCollection[]>;
  fetchRouteBuilderSessionById: (sessionId: string) => Promise<RouteBuilderSessionCollection | null>;
  createRouteWithStops(routeDetails: BuilderRouteDetailsRequest): Promise<APIResponse>;
  updateRouteWithStops(routeId: string, routeDetails: BuilderRouteDetailsRequest): Promise<APIResponse>;
  overrideRoute(routeId: string, routeDetails: BuilderRouteDetailsRequest): Promise<APIResponse>;
  deleteStops: (sessionId: string, stops: NewStop[]) => Promise<APIResponse>;
  updateStops: (sessionId: string, stops: NewStop[]) => Promise<APIResponse>;
}

const routeBuilderService = (httpClient: AxiosInstance): RouteBuilderService => {
  return {
    getRouteBuilder: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<RouteBuilderSessionCollection[]> = await httpClient.get('/stops/route-builder', {
          params,
        });
        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    fetchRouteBuilderSessionById: async (sessionId: string) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return null;
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<RouteBuilderSessionCollection> = await httpClient.get(
          '/stops/route-builder/' + sessionId,
          {
            params,
          },
        );
        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    createRouteWithStops: async (builderRouteDetails) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return { status: false, message: 'Failed to create route with stops' };
        }

        const diff = jsonDifference(builderRouteDetails);
        const body = { ...builderRouteDetails, role: authData.userDetails.role, trust: selectedTrust._id, diff };
        const res: AxiosResponse<APIResponse> = await httpClient.post('/stops/route-builder/create/route', body);
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to create route with stops' };
      }
    },

    // TODO:
    updateRouteWithStops: async (routeId: string, builderRouteDetails: BuilderRouteDetailsRequest) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return { status: false, message: 'Failed to create route with stops' };
        }

        const diff = jsonDifference({ ...builderRouteDetails, routeId });
        const body = { ...builderRouteDetails, role: authData.userDetails.role, trust: selectedTrust._id, diff };
        const res: AxiosResponse<APIResponse> = await httpClient.put(
          '/stops/route-builder/update/route/' + routeId,
          body,
        );
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to create route with stops' };
      }
    },

    // TODO:
    overrideRoute: async (routeId: string, builderRouteDetails: BuilderRouteDetailsRequest) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return { status: false, message: 'Failed to override route' };
        }

        const body = { ...builderRouteDetails, role: authData.userDetails.role, trust: selectedTrust._id };
        const res: AxiosResponse<APIResponse> = await httpClient.put(
          '/stops/route-builder/override/route/' + routeId,
          body,
        );
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to override route' };
      }
    },

    deleteStops: async (sessionId: string, stops: NewStop[]) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post(
          '/stops/route-builder/delete-stops/' + sessionId,
          stops,
        );
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to remove stops' };
      }
    },

    updateStops: async (sessionId: string, stops: NewStop[]) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.put('/stops/route-builder/update-stops/' + sessionId, {
          stops,
        });
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to update stops' };
      }
    },
  };
};

export default routeBuilderService;
