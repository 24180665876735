import { Obj } from './core-model';
import { SidebarMenuItem } from './sidebar.model';
import { StudentToUpdateProfile } from './student-model';

export enum PermissionActionTypes {
  PERMISSION = 'PERMISSION',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
}

export interface Permissions {
  permissions: Obj;
  sideBarMenu: SidebarMenuItem[];
}

export interface PermissionAction {
  type: PermissionActionTypes;
  payload: Permissions;
}

// "export" action have some errors
export enum PermissionKey {
  DASHBOARD_READ = 'dashboard.read',
  DASHBOARD_FORCE_STOP_SESSION = 'dashboard.write',

  DEVELOPER_API_READ = 'developer_api.read',
  DEVELOPER_API_WRITE = 'developer_api.write',

  DEVICE_RFID_READ = 'device_rfid.read',
  DEVICE_RFID_WRITE = 'device_rfid.write',

  DEVICE_READ = 'device.read',
  DEVICE_WRITE = 'device.write',

  LOG_READ = 'log.read',
  LOG_WRITE = 'log.write',

  PANIC_READ = 'panic.read',
  PANIC_WRITE = 'panic.write',

  PARENT_INFO_READ = 'parent_info.read',
  PARENT_INFO_WRITE = 'parent_info.write',

  POLICY_READ = 'policy.read',
  POLICY_WRITE = 'policy.write',

  REGISTRATION_STATUS_READ = 'registration_status.read',

  ROUTE_READ = 'route.read',
  ROUTE_WRITE = 'route.write',

  ROUTE_BUILDER_RFID_READ = 'route_builder_rfid.read',
  ROUTE_BUILDER_RFID_WRITE = 'route_builder_rfid.write',

  ROUTE_RFID_READ = 'route_rfid.read',
  ROUTE_RFID_WRITE = 'route_rfid.write',

  SCHOOL_WRITE = 'school.write',
  SCHOOL_READ = 'school.read',

  STAFF_WRITE = 'staff.write',
  STAFF_READ = 'staff.read',

  STAFF_RFID_READ = 'staff_rfid.read',
  STAFF_RFID_WRITE = 'staff_rfid.write',

  STOP_WRITE = 'stop.write',
  STOP_READ = 'stop.read',

  STUDENT_RFID_READ = 'student_rfid.read',
  STUDENT_RFID_WRITE = 'student_rfid.write',

  STUDENT_WRITE = 'student.write',
  STUDENT_READ = 'student.read',
  STUDENT_CREATE_TEST_ACCOUNT = 'student.create_test_account',

  PROFILE_READ = 'profile.read',
  PROFILE_WRITE = 'profile.write',

  TRUST_READ = 'trust.read',
  TRUST_WRITE = 'trust.write',

  USER_MANAGEMENT_READ = 'user_management.read',
  USER_MANAGEMENT_WRITE = 'user_management.write',

  USER_MANAGEMENT_ROLE_READ = 'user_management_role.read',
  USER_MANAGEMENT_ROLE_WRITE = 'user_management_role.write',
}

export interface ModuleWithActions {
  code: string;
  actions: string[];
}

export interface UpdateProfileRequest {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  students: StudentToUpdateProfile;
}
