import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { Stop, StopInRoutes, StopUploadResponse, UpdateStopsRequest } from '../models/stop-model';
import { StudentCollection } from '../models/student-model';
import { store } from '../store/store';
import { jsonDifference } from '../utils/core-utils';
import { ParsedImportStopData } from './../models/stop-model';

interface StopService {
  getStops: () => Promise<Stop[]>;
  addStop: (stop: Stop) => Promise<APIResponse>;
  updateStop: (stop: Stop, initialStop: Stop) => Promise<APIResponse>;
  updateStopInfo: (stopRequest: UpdateStopsRequest) => Promise<APIResponse>;
  deleteStop: (stop: Stop) => Promise<APIResponse>;
  getStudentsUnderStop: (stop: Stop) => Promise<StudentCollection[]>;
  getRoutesByStop: (stop: Stop) => Promise<StopInRoutes>;
  importStops: (fileData: FormData) => Promise<StopUploadResponse>;
  addBulkStopsOnImport: (data: ParsedImportStopData[], key: number | string) => Promise<APIResponse>;
}

const errorResponse: APIResponse = {
  status: false,
};

const stopService = (httpClient: AxiosInstance): StopService => {
  return {
    getStops: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<Stop[]> = await httpClient.get('/stops', { params });

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    addStop: async (stop) => {
      const diff = jsonDifference(stop);
      const res: AxiosResponse<APIResponse> = await httpClient.post('/stops', { ...stop, diff });
      return res.data;
    },

    updateStop: async (stop, initialStop) => {
      const diff = jsonDifference(stop, initialStop);
      const res: AxiosResponse<APIResponse> = await httpClient.post('/stops/update', { ...stop, diff });
      return res.data;
    },

    updateStopInfo: async (stopRequest) => {
      try {
        // TODO:
        const res: AxiosResponse<APIResponse> = await httpClient.post('/stops/updatestopinfo', stopRequest);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    deleteStop: async (stop) => {
      const res: AxiosResponse<APIResponse> = await httpClient.post('/stops/delete/' + stop._id);
      return res.data;
    },

    getStudentsUnderStop: async (stop) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id, stopId: stop._id };
        const res: AxiosResponse<StudentCollection[]> = await httpClient.post('/stops/stop', params);

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    getRoutesByStop: async (stop) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return { pickupDetails: [], dropoffDetails: [] };
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id, stopId: stop._id };
        const res: AxiosResponse<StopInRoutes> = await httpClient.post('/stops/routes', params);

        return res.data;
      } catch (err) {
        console.log(err);
        return { pickupDetails: [], dropoffDetails: [] };
      }
    },

    importStops: async (data: FormData) => {
      const errorResponse: StopUploadResponse = { error: 'Upload failed', filePath: '', resultData: null };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<StopUploadResponse> = await httpClient.post('/stops/import', data);
        return res.data;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    addBulkStopsOnImport: async (data, key) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/stops/stop-list', { data, key });
        return res.data;
      } catch (err) {
        return { status: false, result: data };
      }
    },
  };
};

export default stopService;
