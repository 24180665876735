import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { StaticDataCollection } from '../models/static-data-model';
import { jsonDifference } from '../utils/core-utils';

interface ParentService {
  getPolicyUrls: () => Promise<StaticDataCollection[]>;
  savePolicyUrl: (name: string, data: StaticDataCollection, initialData: StaticDataCollection) => Promise<APIResponse>;
}

const parentalAboutService = (httpClient: AxiosInstance): ParentService => {
  return {
    getPolicyUrls: async () => {
      try {
        const res: AxiosResponse<StaticDataCollection[]> = await httpClient.get('/parent/policy');

        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    savePolicyUrl: async (id, data, initialData) => {
      try {
        const diff = jsonDifference(data, initialData);
        const res: AxiosResponse<APIResponse> = await httpClient.post('/parent/policy/' + id, { ...data, diff });

        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Token invalid' };
      }
    },
  };
};

export default parentalAboutService;
