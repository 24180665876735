import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { AdminVerifyUserRequest } from '../models/user-management-model';

interface EmailService {
  verifyEmail: (token: string) => Promise<APIResponse>;
  verifyUser: (userData: AdminVerifyUserRequest) => Promise<APIResponse>;
}

const emailService = (httpClient: AxiosInstance): EmailService => {
  return {
    verifyEmail: async (token: string) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/users/verify-email', { token });

        return res.data;
      } catch (err) {
        return { status: false, message: 'Token invalid' };
      }
    },
    verifyUser: async (userData: AdminVerifyUserRequest) => {
      try {
        const res: AxiosResponse<APIResponse> = await httpClient.post('/user-management/user/verify', { ...userData });

        return res.data;
      } catch (err) {
        return { status: false, message: 'Token invalid' };
      }
    },
  };
};

export default emailService;
