import { ConnectionActions, ConnectionState } from '../../models/connection-model';

const initialState = { disconnected: false };

const connectionReducer = (state = initialState, action: ConnectionActions): ConnectionState => {
  switch (action.type) {
    case 'DISCONNECTED':
      return { ...state, disconnected: true };
    case 'CONNECTED':
      return { ...state, disconnected: false };
    default:
      return state;
  }
};

export { connectionReducer };
